import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { version } from "../../package.json";

function Footer(props) {
    let margin = props.margin ? props.margin : 25;
    return (
        <div style={{ marginBottom: margin }}>
            <Typography variant="body2" color="textSecondary" align="center">
                <FontAwesomeIcon icon="envelope" /> info@stay-u-nique.com
                <br />
                <FontAwesomeIcon icon="phone-alt" />
                +34 932 750 423
                <br />
                <FontAwesomeIcon icon={["fab", "whatsapp"]} /> +34 932 750 423 (10:00h-18:00h CET GMT+1)
                <br />
                <Link color="inherit" target="_blank" href="https://stay-u-nique.com/">
                    {"© "} Stay U-nique {new Date().getFullYear()}
                </Link>{" "}
                {/* <span onClick={() => window.location.reload()}>v{version}</span> */}
            </Typography>
        </div>
    );
}

export default Footer;
