import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import fetchClient from "../components/utils/fetchClient";

import withAuth from "../components/auth/withAuth";

import { withTranslation } from "react-multi-lang";

import QRCode from "qrcode.react";

import Footer from "./Footer";
import Navbar from "./Navbar";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

class WiFi extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            booking: []
        };
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.WiFi");

        let booking = await this.fetchBooking();

        this.setState({
            booking: booking,
            isLoading: false
        });
    }

    async fetchBooking() {
        return new Promise(function(resolve, reject) {
            fetchClient
                .get("guests/booking")
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.WiFi")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {this.state.isLoading ? (
                                        <CircularProgress className={classes.progress} />
                                    ) : (
                                        <>
                                            {this.state.booking.wifiName && this.state.booking.wifiPass && (
                                                <>
                                                    <Typography variant="body1" paragraph>
                                                        {t("wifi.WifiName")} : {this.state.booking.wifiName}
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        {t("wifi.WifiPassword")} : {this.state.booking.wifiPass}
                                                    </Typography>
                                                    <QRCode value={"WIFI:T:WPA;S:" + this.state.booking.wifiName + ";P:" + this.state.booking.wifiPass + ";;"} />
                                                </>
                                            )}
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(WiFi)));
