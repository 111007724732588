import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import TextField from "./fields/TextField";

import fetchClient from "../components/utils/fetchClient";

import withAuth from "../components/auth/withAuth";

import { Form, Field } from "react-final-form";

import { withTranslation } from "react-multi-lang";

import Footer from "./Footer";
import Navbar from "./Navbar";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    formControl: {
        marginTop: 5,
        marginBottom: 20
    }
});

const starRating = ({ input, label, disabled }) => (
    <div>
        <Typography>{label}</Typography>
        <Rating size="large" name={input.name} onChange={input.onChange} value={parseInt(input.value)} disabled={disabled} />
    </div>
);

class Rate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isDisabled: false,
            rating: []
        };

        this.saveChanges = this.saveChanges.bind(this);
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Rate");

        let rating = await this.fetchRating();

        this.setState({
            rating: rating,
            isLoading: false,
            canComplete: Boolean(rating.canComplete),
            isDisabled: Boolean(rating.isComplete),
            didComplete: false
        });
    }

    saveChanges(values) {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("guests/rating", values)
            .then(function (response) {
                self.setState({ didComplete: true });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async fetchRating() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("guests/rating")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("rate.Required"));

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Rate")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {this.state.isLoading ? (
                                        <CircularProgress className={classes.progress} />
                                    ) : (
                                        <>
                                            {this.state.canComplete ? (
                                                <>
                                                    {!this.state.didComplete ? (
                                                        <Form
                                                            onSubmit={this.saveChanges}
                                                            initialValues={this.state.rating}
                                                            render={({ handleSubmit, invalid, submitting }) => (
                                                                <form onSubmit={handleSubmit}>
                                                                    <Field
                                                                        fullWidth
                                                                        name="firstNight"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={!this.state.rating.isMidTerm ? t("rate.RateFirstNight") : t("rate.RateFirstWeek")}
                                                                        validate={required}
                                                                    />
                                                                    <Field
                                                                        name="firstNightComment"
                                                                        disabled={this.state.isDisabled}
                                                                        label={t("rate.Comment")}
                                                                        className={classes.formControl}
                                                                        multiline
                                                                        fullWidth
                                                                        component={TextField}
                                                                    />

                                                                    {this.state.rating.isMidTerm === 1 && (
                                                                        <>
                                                                            <Field
                                                                                fullWidth
                                                                                name="apartmentConditions"
                                                                                disabled={this.state.isDisabled}
                                                                                component={starRating}
                                                                                type="text"
                                                                                label={t("rate.ApartmentConditions")}
                                                                                validate={required}
                                                                            />

                                                                            <Field
                                                                                name="apartmentConditionsComment"
                                                                                disabled={this.state.isDisabled}
                                                                                label={t("rate.Comment")}
                                                                                className={classes.formControl}
                                                                                multiline
                                                                                fullWidth
                                                                                component={TextField}
                                                                            />
                                                                        </>
                                                                    )}

                                                                    <Field
                                                                        fullWidth
                                                                        name="agent"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={
                                                                            this.state.rating.autoCheckin
                                                                                ? t("rate.RateAutoCheckin")
                                                                                : this.state.rating.agentNames.length
                                                                                ? t("rate.RateAgent") + " (" + this.state.rating.agentNames + ")"
                                                                                : null
                                                                        }
                                                                        validate={required}
                                                                    />
                                                                    <Field
                                                                        name="agentComment"
                                                                        disabled={this.state.isDisabled}
                                                                        label={t("rate.Comment")}
                                                                        className={classes.formControl}
                                                                        multiline
                                                                        fullWidth
                                                                        component={TextField}
                                                                    />

                                                                    <Field
                                                                        fullWidth
                                                                        name="cleaning"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={t("rate.RateCleanliness")}
                                                                        validate={required}
                                                                    />

                                                                    <Field
                                                                        name="cleaningComment"
                                                                        disabled={this.state.isDisabled}
                                                                        label={t("rate.Comment")}
                                                                        className={classes.formControl}
                                                                        multiline
                                                                        fullWidth
                                                                        component={TextField}
                                                                    />

                                                                    <Button variant="contained" type="submit" color="primary" disabled={invalid || submitting || this.state.isDisabled}>
                                                                        {t("rate.Submit")}
                                                                    </Button>
                                                                </form>
                                                            )}
                                                        />
                                                    ) : (
                                                        <Typography>{t("rate.SubmitSuccess")}</Typography>
                                                    )}
                                                </>
                                            ) : (
                                                <Typography>{t("rate.Unavailable")}</Typography>
                                            )}
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Rate)));
