import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from "react-multi-lang";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import { Field } from "react-final-form";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: "White"
    }
});

const IfCondition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

class TouristType extends React.Component {
    render() {
        const required = value => (value ? undefined : t("checkin.Required"));

        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("guide.TouristTypeDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                    <IfCondition when="segment" is={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Field name="touristtype" parse={() => 1} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 1 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/tourist_types/majorattractions.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.TouristType1")}
                                                title={t("guide.TouristType1")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.TouristType1")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.TouristType1Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Field name="touristtype" parse={() => 2} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 2 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/tourist_types/hiddengems.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.TouristType2")}
                                                title={t("guide.TouristType2")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.TouristType2")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.TouristType2Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                    </IfCondition>
                    <IfCondition when="segment" is={2}>
                        <Grid item xs={12} md={4} lg={4}>
                            <Field name="touristtype" parse={() => 1} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 1 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/tourist_types/party.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsTouristType1")}
                                                title={t("guide.FriendsTouristType1")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FriendsTouristType1")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FriendsTouristType1Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Field name="touristtype" parse={() => 2} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 2 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/tourist_types/sightseeing.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsTouristType2")}
                                                title={t("guide.FriendsTouristType2")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FriendsTouristType2")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FriendsTouristType2Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <Field name="touristtype" parse={() => 3} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 3 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/tourist_types/events.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsTouristType3")}
                                                title={t("guide.FriendsTouristType3")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FriendsTouristType3")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FriendsTouristType3Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                    </IfCondition>
                    <IfCondition when="segment" is={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Field name="touristtype" parse={() => 1} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 1 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/tourist_types/majorattractions.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.TouristType1")}
                                                title={t("guide.TouristType1")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.TouristType1")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.TouristType1Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Field name="touristtype" parse={() => 2} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 2 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/tourist_types/hiddengems.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.TouristType2")}
                                                title={t("guide.TouristType2")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.TouristType2")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.TouristType2Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                    </IfCondition>
                </Grid>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(TouristType));
