import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { withTranslation } from "react-multi-lang";
import withAuth from "../components/auth/withAuth";
import Footer from "./Footer";
import Navbar from "./Navbar";

import fetchClient from "../components/utils/fetchClient";

import moment from "moment";
import "moment/locale/es";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    messageArea: {
        height: "70vh",
        overflowY: "auto"
    }
});

const stylesForMessage = {
    avatar: { maxWidth: 32, maxHeight: 32, marginRight: 5 },
    userName: {
        display: "inline-block",
        marginRight: 5
    },
    timestamp: { display: "inline-block" }
};

const Message = withStyles(stylesForMessage)(({ message, classes }) => {
    const {
        messageId,
        user: { avatarUrl, userName },
        timestamp,
        text,
        isIncoming
    } = message;
    return (
        <ListItem key={messageId}>
            <Grid container>
                <Grid item xs={12}>
                    <ListItemText
                        classes={classes.background}
                        align={isIncoming ? "right" : "left"}
                        secondary={<span dangerouslySetInnerHTML={{ __html: text.replace(/(?:\r\n|\r|\n)/g, "<br />") }}></span>}
                        primary={
                            <>
                                <Avatar className={classes.avatar} alt={userName} src={avatarUrl} />
                                {userName}
                            </>
                        }
                    ></ListItemText>
                </Grid>
                <Grid item xs={12}>
                    <ListItemText align={isIncoming ? "right" : "left"} secondary={moment(timestamp).format("DD/MM HH:mm")}></ListItemText>
                </Grid>
            </Grid>
        </ListItem>
    );
});

class Chat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            messages: [],
            isSending: false,
            messageContent: ""
        };
        this.handleChange = this.handleChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.messagesEndRef = React.createRef();
    }

    async componentDidMount() {
        this.interval = setInterval(() => this.refetchMessages(), 20000);

        document.title = "Stay U-nique | " + this.props.t("titles.Chat");

        let messages = await this.fetchMessages();

        this.setState(
            {
                messages: messages,
                isLoading: false
            },
            () => this.scrollToBottom()
        );
    }

    async refetchMessages() {
        let messages = await this.fetchMessages();

        this.setState(
            {
                messages: messages,
                isLoading: false
            },
            () => this.scrollToBottom()
        );
    }

    handleChange(e) {
        this.setState({ messageContent: e.target.value });
    }

    keyPress(e) {
        if (e.keyCode === 13) {
            this.sendMessage();
        }
    }

    scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    sendMessage() {
        var self = this;

        if (self.state.messageContent.length === 0) {
            return false;
        }

        self.setState({ isSending: true });

        fetchClient
            .post("guests/messages", { messageContent: self.state.messageContent })
            .then(function (response) {
                self.refetchMessages();
                self.setState({ isSending: false, messageContent: "" });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async fetchMessages() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("guests/messages")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Chat")}
                        </Typography>
                        <Paper className={classes.paper}>
                            {!this.state.isLoading ? (
                                <>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <List className={classes.messageArea}>
                                                {this.state.messages.messages.map(message => (
                                                    <Message message={message} key={message.id} />
                                                ))}
                                                <div ref={this.messagesEndRef} />
                                            </List>
                                        </Grid>
                                    </Grid>
                                    {this.state.isSending ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <TextField label={t("chat.WriteMessage")} value={this.state.messageContent} onKeyDown={this.keyPress} onChange={this.handleChange}></TextField>
                                    )}
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Paper>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Chat)));
