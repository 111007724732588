import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import LanguageIcon from "@material-ui/icons/Language";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import NoSsr from "@material-ui/core/NoSsr";
import Menu from "@material-ui/core/Menu";
import Badge from "@material-ui/core/Badge";

import { withRouter } from "react-router";

import NavItems from "./NavItems";
import StayUniqueLogo from "./Logo";

import { withTranslation, getLanguage, setLanguage } from "react-multi-lang";

const drawerWidth = 250;

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        background: "linear-gradient(5deg, #4e5e9e 3% ,#0cb6eb 97%)",
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        background: "linear-gradient(5deg, #0cb6eb 3%,#4e5e9e 97% )",
        color: "white",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    language: {
        margin: theme.spacing(0, 1, 0, 0.5),
    },
});

export const languages = [
    {
        code: "en",
        icon: "🇬🇧",
        name: "English",
    },
    {
        code: "es",
        icon: "🇪🇸",
        name: "Español",
    },
];

class Navbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            languageAnchorEl: null,
            open: false,
        };

        this.handleLanguageMenuClose = this.handleLanguageMenuClose.bind(this);
        this.handleLanguageIconClick = this.handleLanguageIconClick.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
    }

    handleLanguageIconClick(event) {
        this.setState({ languageAnchorEl: event.currentTarget });
    }

    handleLanguageMenuClose() {
        this.setState({ languageAnchorEl: null });
    }

    handleChangeLanguage(lang) {
        localStorage.setItem("guestAppSettings", JSON.stringify({ language: lang }));
        this.handleLanguageMenuClose();
        setLanguage(lang);
        window.location.reload(); //ugly
    }

    handleDrawerOpen() {
        this.setState({ open: true });
    }

    handleDrawerClose() {
        this.setState({ open: false });
    }

    render() {
        const userLanguage = getLanguage();

        const userLanguageText = languages.find((arr) => arr.code === userLanguage).code.toUpperCase();

        const { t, classes, history } = this.props;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, this.state.open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen}
                            className={clsx(classes.menuButton, this.state.open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            <StayUniqueLogo />
                        </Typography>

                        <div className={clsx(this.state.open && classes.menuButtonHidden)}>
                            <IconButton
                                color="inherit"
                                aria-owns={this.state.languageAnchorEl ? "language-menu" : undefined}
                                aria-haspopup="true"
                                aria-label={t("nav.ChangeLanguage")}
                                onClick={this.handleLanguageIconClick}
                            >
                                <Badge color="secondary" badgeContent={userLanguageText} className={classes.margin}>
                                    <LanguageIcon style={{ color: "white" }} />
                                </Badge>
                            </IconButton>
                            <NoSsr>
                                <Menu id="language-menu" anchorEl={this.state.languageAnchorEl} open={Boolean(this.state.languageAnchorEl)} onClose={this.handleLanguageMenuClose}>
                                    {languages.map((language) => (
                                        <MenuItem
                                            component="a"
                                            data-no-link="true"
                                            key={language.code}
                                            selected={userLanguage === language.code}
                                            onClick={() => this.handleChangeLanguage(language.code)}
                                        >
                                            {language.icon} {language.name}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </NoSsr>
                        </div>
                    </Toolbar>
                </AppBar>

                <Drawer
                    ModalProps={{ onBackdropClick: this.handleDrawerClose }}
                    classes={{
                        paper: clsx(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                    </div>
                    <Divider />

                    <List>
                        <NavItems currentPath={history.location.pathname} />
                    </List>
                </Drawer>
            </div>
        );
    }
}

export default withRouter(withTranslation(withStyles(styles)(Navbar)));
