import decode from "jwt-decode";
import { setLanguage } from "react-multi-lang";

export default class AuthService {
    constructor(domain) {
        this.domain = domain || process.env.REACT_APP_API_URL;
        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.fetch = this.fetch.bind(this); // React binding stuff
    }

    login(lastName, refId) {
        // Get a token
        return this.fetch(`${this.domain}/guests/login`, {
            method: "POST",
            body: JSON.stringify({
                lastName,
                refId
            })
        }).then(res => {
            this.setToken(res.token); // Setting the token in localStorage
            this.setAppLanguage();
            return Promise.resolve(res);
        });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Math.round(new Date().getTime() / 1000)) {
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }

    setToken(token) {
        // Saves user token to localStorage
        localStorage.setItem("guestAppToken", token);
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem("guestAppToken");
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem("guestAppToken");
        localStorage.removeItem("guestAppSettings");
    }

    getProfile() {
        return decode(this.getToken());
    }

    setAppLanguage() {
        const profile = this.getProfile();

        let language = "en";
        if (profile.lang && ["en", "es"].includes(profile.lang.toLowerCase())) {
            language = profile.lang.toLowerCase();
        }
        setLanguage(language);
        localStorage.setItem("guestAppSettings", JSON.stringify({ language }));
    }

    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json"
        };

        if (this.loggedIn()) {
            headers["Authorization"] = "Bearer " + this.getToken();
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json());
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            // Success status lies between 200 to 300
            return response;
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }
}
