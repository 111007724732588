import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import fetchClient from "../components/utils/fetchClient";

import { withTranslation } from "react-multi-lang";

import Footer from "./Footer";
import Navbar from "./Navbar";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

class Payment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            payment: {
                urlTPV: "",
                signatureVersion: "",
                merchantParams: "",
                signature: ""
            }
        };

        this.formRef = React.createRef();
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Payment");

        let payment = await this.fetchPayment();

        this.setState(
            {
                payment: payment,
                isLoading: false
            },
            () => {
                if (payment.success) {
                    this.formRef.current.submit();
                }
            }
        );
    }

    async fetchPayment() {
        var self = this;

        let backTo = localStorage.getItem("guestAppStep");

        return new Promise(function(resolve, reject) {
            fetchClient
                .post("guests/payment/" + self.props.match.params.id, {backTo: backTo})
                .then(function(response) {
                    resolve(response.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Payment")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {this.state.isLoading ? (
                                        <CircularProgress className={classes.progress} />
                                    ) : (
                                        <>
                                            {this.state.payment.success ? (
                                                <>
                                                    <Typography>{t("payment.Redirecting") + "..."}</Typography>
                                                    <form ref={this.formRef} action={this.state.payment.data.urlTPV} method="post">
                                                        <input type="hidden" name="Ds_SignatureVersion" value={this.state.payment.data.signatureVersion} />
                                                        <input type="hidden" name="Ds_MerchantParameters" value={this.state.payment.data.merchantParams} />
                                                        <input type="hidden" name="Ds_Signature" value={this.state.payment.data.signature} />
                                                    </form>
                                                </>
                                            ) : (
                                                <Typography>
                                                    {t("payment.Error")} ({this.state.payment.message})
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(Payment));
