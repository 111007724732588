import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SignatureCanvas from "react-signature-canvas";

import fetchClient from "../components/utils/fetchClient";

import Footer from "./Footer";
import Navbar from "./Navbar";

import TabPanel from "./TabPanel";

import arrayMutators from "final-form-arrays";

import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { withTranslation } from "react-multi-lang";

import withAuth from "../components/auth/withAuth";

const styles = (theme) => ({
    root: {
        display: "flex",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black",
        height: "100%",
    },
    completedMessage: {
        marginTop: 15,
    },
});

function Link(props) {
    return (
        <Button color="primary" target="_blank" variant="contained" href={props.input.value}>
            {props.t("contracts.ViewContract")}
        </Button>
    );
}

function Contract(props) {
    return <div dangerouslySetInnerHTML={{ __html: props.input.value }}></div>;
}

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
    };
}

const updateValue = ([name, newvalue], state, { changeValue }) => {
    changeValue(state, name, (value) => newvalue);
};

class Contracts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 0,
            isLoading: true,
        };

        this.saveChanges = this.saveChanges.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearPad = this.clearPad.bind(this);

        this.sigCanvas = new Map();
    }

    clearPad(index, mutators) {
        const canvas = this.sigCanvas.get(index);
        canvas.clear();

        mutators.updateValue(`contracts.${index}.signature`, "");
    }

    handleChange(event, newValue) {
        this.setState({
            currentTab: newValue,
        });
    }

    toDataUrl(index, mutators, input) {
        const canvas = this.sigCanvas.get(index);
        let image = canvas.getTrimmedCanvas().toDataURL("image/png");

        input.onChange(image);
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Contracts");

        let booking = await this.fetchContracts();

        this.setState({
            booking: booking,
            isLoading: false,
        });
    }

    async fetchContracts() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("guests/contracts")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    saveChanges(values) {
        var self = this;

        self.setState({ isDisabled: true });
        fetchClient
            .post("/guests/contracts", values)
            .then(function (response) {
                fetchClient
                    .get("/guests/contracts")
                    .then(function (response) {
                        self.setState({ booking: response.data.data });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { t, classes } = this.props;

        const required = (value) => (value ? undefined : t("contracts.Required"));

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Contracts")}
                        </Typography>
                        <Paper className={classes.paper}>
                            {this.state.isLoading ? (
                                <CircularProgress className={classes.progress} />
                            ) : (
                                <>
                                    {this.state.booking.contracts.length ? (
                                        <>
                                            <Form
                                                onSubmit={this.saveChanges}
                                                mutators={{
                                                    ...arrayMutators,
                                                    updateValue,
                                                }}
                                                initialValues={this.state.booking}
                                                render={({ form, handleSubmit, values, submitting, invalid, form: { mutators } }) => {
                                                    return (
                                                        <form onSubmit={handleSubmit} style={{ padding: 10 }}>
                                                            <AppBar position="static">
                                                                <Tabs variant="scrollable" value={this.state.currentTab} onChange={this.handleChange} aria-label={t("contracts.Contracts")}>
                                                                    {this.state.booking.contracts.map((contract, index) => (
                                                                        <Tab value={index} key={index} label={contract.contractName} {...a11yProps(index)} />
                                                                    ))}
                                                                </Tabs>
                                                            </AppBar>
                                                            <FieldArray name="contracts">
                                                                {({ fields }) => (
                                                                    <>
                                                                        {fields.map((contract, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <TabPanel value={this.state.currentTab} index={index}>
                                                                                    <Field name={`${contract}.contractBody`} component={Contract} />
                                                                                    {values.contracts[index].hasCompleted === 0 ? (
                                                                                        <>
                                                                                            <Field name={`${contract}.signature`} validate={required}>
                                                                                                {({ input, meta }) => (
                                                                                                    <SignatureCanvas
                                                                                                        penColor="black"
                                                                                                        ref={(ref) => this.sigCanvas.set(index, ref)}
                                                                                                        canvasProps={{ width: 800, height: 200 }}
                                                                                                        onEnd={() => this.toDataUrl(index, mutators, input)}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                            <br />
                                                                                            <Button
                                                                                                color="primary"
                                                                                                size="small"
                                                                                                onClick={() => {
                                                                                                    this.clearPad(index, mutators);
                                                                                                }}
                                                                                                variant="contained"
                                                                                            >
                                                                                                {t("contracts.ClearSig")}
                                                                                            </Button>
                                                                                            <br /> <br />
                                                                                            <Button
                                                                                                type="submit"
                                                                                                onClick={() => this.saveChanges(values)}
                                                                                                disabled={this.state.isDisabled || invalid}
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                            >
                                                                                                {t("contracts.Sign")}
                                                                                            </Button>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className={classes.completedMessage}>
                                                                                            <Typography variant="h6" paragraph>
                                                                                                {t("contracts.ContractSigned")}
                                                                                            </Typography>
                                                                                            <Field name={`${contract}.contractURL`} t={t} component={Link} />
                                                                                        </div>
                                                                                    )}
                                                                                </TabPanel>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </>
                                                                )}
                                                            </FieldArray>
                                                        </form>
                                                    );
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Typography paragraph>{t("contracts.NoContracts")}</Typography>
                                        </>
                                    )}
                                </>
                            )}
                        </Paper>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Contracts)));
