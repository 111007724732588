import React, { Component } from "react";
import AuthService from "./AuthService";

import Loader from "../Loader";

export default function withAuth(AuthComponent) {
    const Auth = new AuthService();

    return class AuthWrapped extends Component {
        constructor() {
            super();
            this.state = {
                user: null
            };
        }

        b64DecodeUnicode(str) {
            // Going backwards: from bytestream, to percent-encoding, to original string.
            return decodeURIComponent(
                atob(str)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );
        }

        UNSAFE_componentWillMount() {
            if (this.props.match.params.key) {
                Auth.logout();

                this.setState({
                    user: null
                });

                try {
                    const key = this.b64DecodeUnicode(this.props.match.params.key);
                    if (key) {
                        const parts = key.split("-");
                        if (parts.length === 2) {
                            const refId = parts[0];
                            const lastName = parts[1].split("|").join(" ");

                            Auth.login(lastName, refId)
                                .then(res => {
                                    try {
                                        const profile = Auth.getProfile();
                                        this.setState({
                                            user: profile
                                        });
                                    } catch (err) {
                                        Auth.logout();
                                        this.props.history.replace("/login");
                                    }
                                })
                                .catch(err => {
                                    Auth.logout();
                                    this.props.history.replace("/login");
                                });
                        }
                    } else {
                        Auth.logout();
                        this.props.history.replace("/login");
                    }
                } catch (err) {
                    Auth.logout();
                    this.props.history.replace("/login");
                }
            } else {
                try {
                    const profile = Auth.getProfile();
                    this.setState({
                        user: profile
                    });
                } catch (err) {
                    Auth.logout();
                    this.props.history.replace("/login");
                }
            }
        }

        render() {
            if (this.state.user) {
                return <AuthComponent store={this.props.store} match={this.props.match} t={this.props.t} history={this.props.history} user={this.state.user} />;
            } else {
                return <Loader />;
            }
        }
    };
}
