import React from "react";
import { withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden"
    },
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)"
    },
    titleBar: {
        background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
    },
    icon: {
        color: "white"
    }
});

class Gallery extends React.Component {
    render() {
        const { classes, images, name } = this.props;

        return (
            <div className={classes.root}>
                <GridList cellHeight={200} spacing={1} className={classes.gridList}>
                    {images.map((image, index) => (
                        <GridListTile key={index} cols={2} rows={2}>
                            <img src={image} alt={name} />
                            <GridListTileBar title={name} titlePosition="top" className={classes.titleBar} />
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        );
    }
}

export default withStyles(styles)(Gallery);
