import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import UnlockIcon from "@material-ui/icons/LockOpen";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import MomentUtils from "@date-io/moment";

import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";

import moment from "moment";

import "moment/locale/es";

import { withTranslation, getLanguage } from "react-multi-lang";

import withAuth from "../components/auth/withAuth";
import Footer from "./Footer";
import Navbar from "./Navbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withSnackbar } from "notistack";

import fetchClient from "../components/utils/fetchClient";

import { TextField } from "@material-ui/core";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    default: {
        color: theme.palette.primary.main
    },
    error: {
        color: "red"
    },
    success: {
        color: "green"
    },
    disabled: {
        color: "grey"
    },
    messageBox: {
        paddingTop: 25
    },
    smallButton: {
        padding: "0px 5px",
        marginLeft: 10
    }
});

const DatePickerTextField = function (props) {
    return (
        <Button
            color="primary"
            variant="contained"
            onClick={props.onClick} // do not override
            id={props.id} // do not override
            disabled={props.disabled} // do not override
            {...props.inputProps} // do not override
            style={{ marginLeft: 10 }}
        >
            {props.t("gettingin.MarkDelay")}
        </Button>
    );
};

function AlertDialog(props) {
    const { close, buttons, title, content, open } = props;

    return (
        <Dialog open={open} onClose={close} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>{buttons}</DialogActions>
        </Dialog>
    );
}

class GettingIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            checkin: { procedure: "" },
            unlockingBuilding: false,
            unlockingApt: false,
            unlockStateBuilding: "default",
            unlockStateApt: "default",
            messageBoxOpen: false,
            messageContent: "",
            isSending: false,
            open: false,
            title: "",
            content: "",
            completed: true,
            booking: []
        };

        this.unlockBuilding = this.unlockBuilding.bind(this);
        this.unlockApt = this.unlockApt.bind(this);
        this.openMessageBox = this.openMessageBox.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.markOnWay = this.markOnWay.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setDelay = this.setDelay.bind(this);
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.GettingIn");

        const checkin = await this.fetchCheckin();
        let booking = await this.fetchBooking();

        this.setState({
            completed: checkin.success,
            checkin: checkin.data,
            booking: booking,
            isLoading: false
        });
    }

    async fetchBooking() {
        return new Promise(function(resolve, reject) {
            fetchClient
                .get("guests/booking")
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    unlockApt() {
        var self = this;

        self.setState({ unlockingApt: true });

        if (self.state.checkin.unlockAllowed === 0) {
            self.props.enqueueSnackbar(self.props.t("gettingin.TimeMessage"), { preventDuplicate: true, variant: "info" });
            self.setState({ unlockingApt: false });
            return false;
        }

        fetchClient
            .post("guests/smartlock", { action: 1, type: 1 })
            .then(function (response) {
                if (response.data.success) {
                    self.setState({ unlockingApt: false, unlockStateApt: "success" });
                } else {
                    self.setState({ unlockingApt: false, unlockStateApt: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    unlockBuilding() {
        var self = this;
        self.setState({ unlockingBuilding: true });

        if (self.state.checkin.unlockAllowed === 0) {
            self.props.enqueueSnackbar(self.props.t("gettingin.TimeMessage"), { preventDuplicate: true, variant: "info" });
            self.setState({ unlockingBuilding: false });
            return false;
        }

        fetchClient
            .post("guests/smartlock", { action: 1, type: 2 })
            .then(function (response) {
                if (response.data.success) {
                    self.setState({ unlockingBuilding: false, unlockStateBuilding: "success" });
                } else {
                    self.setState({ unlockingBuilding: false, unlockStateBuilding: "error" });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    openMessageBox() {
        this.setState({ messageBoxOpen: true });
    }

    markOnWay() {
        var self = this;

        fetchClient
            .post("guests/messages", { messageContent: "I'm on my way!" })
            .then(function (response) {
                self.handleOpen(self.props.t("gettingin.MarkOnWayTitle"), self.props.t("gettingin.MarkOnWayMessage"));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    setDelay(time) {
        const newTime = moment(time).format("HH:mm");

        var self = this;

        fetchClient
            .post("guests/messages", { messageContent: "My new expected time is " + newTime })
            .then(function (response) {
                self.handleOpen(self.props.t("gettingin.MarkOnWayTitle"), self.props.t("gettingin.MarkDelayMessage"));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleChange = event => {
        this.setState({
            messageContent: event.target.value
        });
    };

    sendMessage() {
        var self = this;
        self.setState({ isSending: true });

        fetchClient
            .post("guests/messages", { messageContent: self.state.messageContent })
            .then(function (response) {
                self.setState({ isSending: false, messageContent: null, messageBoxOpen: false });
                self.handleOpen(self.props.t("gettingin.SendTitle"), self.props.t("gettingin.SendMessage"));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleOpen(title, content) {
        this.setState({ open: true, title: title, content: content });
    }

    handleClose() {
        this.setState({
            open: false
        });
    }

    async fetchCheckin() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("guests/getting-in")
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.GettingIn")}
                        </Typography>
                        <Paper className={classes.paper}>
                            {!this.state.isLoading ? (
                                <>
                                    {!this.state.completed ? (
                                        <>
                                            <Typography paragraph component="div">
                                                {t("gettingin.missingData")}
                                            </Typography>
                                            <Button
                                                className={classes.smallButton}
                                                onClick={() => this.props.history.push("/arrival")}
                                                aria-label={t("gettingin.understood")}
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                            >
                                                {t("gettingin.understood")}
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            {this.state.checkin.procedure.length > 0 && (
                                                <>
                                                    <Typography paragraph component="div">
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.checkin.procedure }}></div>
                                                    </Typography>
                                                </>
                                            )}
                                            {this.state.checkin.checkinProcedureId !== 1 && this.state.checkin.checkinProcedureId !== 27 && this.state.checkin.checkinProcedureId !== 34 ? (
                                                <>
                                                    {this.state.checkin.buildingCode && (
                                                        <>
                                                            <Typography variant="body1" paragraph>
                                                                {t("gettingin.BuildingCode")} : {this.state.checkin.buildingCode}
                                                            </Typography>
                                                        </>
                                                    )}
                                                    {this.state.checkin.hasSmartLock > 0 && (
                                                        <>
                                                            <Typography variant="h6" paragraph>
                                                                {t("gettingin.RequiredInfo")}
                                                            </Typography>

                                                            {this.state.checkin.hasDoneRequired === 0 ? (
                                                                <>
                                                                    <Typography paragraph>{t("gettingin.RequiredInfoDesc")}</Typography>
                                                                    <Typography>
                                                                        {t("gettingin.Passports")}{" "}
                                                                        {this.state.checkin.hasPassports ? (
                                                                            <FontAwesomeIcon style={{ color: "green" }} icon="check" />
                                                                        ) : (
                                                                            <>
                                                                                <FontAwesomeIcon style={{ color: "red" }} icon="times" />
                                                                                <Button
                                                                                    className={classes.smallButton}
                                                                                    onClick={() => this.props.history.push("/passport")}
                                                                                    aria-label={t("gettingin.Complete")}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                >
                                                                                    {t("gettingin.Complete")}
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </Typography>
                                                                    <Typography>
                                                                        {t("gettingin.Tax")}{" "}
                                                                        {this.state.checkin.taxPaid ? (
                                                                            <FontAwesomeIcon style={{ color: "green" }} icon="check" />
                                                                        ) : (
                                                                            <>
                                                                                <FontAwesomeIcon style={{ color: "red" }} icon="times" />
                                                                                <Button
                                                                                    className={classes.smallButton}
                                                                                    onClick={() => this.props.history.push("/payments-step")}
                                                                                    aria-label={t("gettingin.PayNow")}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                >
                                                                                    {t("gettingin.PayNow")}
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </Typography>

                                                                    <Typography>
                                                                        {t("gettingin.Deposit")}{" "}
                                                                        {this.state.checkin.depositDone ? (
                                                                            <FontAwesomeIcon style={{ color: "green" }} icon="check" />
                                                                        ) : (
                                                                            <>
                                                                                <FontAwesomeIcon style={{ color: "red" }} icon="times" />
                                                                                <Button
                                                                                    className={classes.smallButton}
                                                                                    onClick={() => this.props.history.push("/payments-step")}
                                                                                    aria-label={t("gettingin.PayNow")}
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                >
                                                                                    {t("gettingin.PayNow")}
                                                                                </Button>
                                                                            </>
                                                                        )}
                                                                    </Typography>

                                                                    {this.state.checkin.needsContract === 1 && (
                                                                        <Typography>
                                                                            {t("gettingin.Contract")}{" "}
                                                                            {this.state.checkin.contractDone ? (
                                                                                <FontAwesomeIcon style={{ color: "green" }} icon="check" />
                                                                            ) : (
                                                                                <>
                                                                                    <FontAwesomeIcon style={{ color: "red" }} icon="times" />
                                                                                    <Button
                                                                                        className={classes.smallButton}
                                                                                        onClick={() => this.props.history.push("/contracts")}
                                                                                        aria-label={t("gettingin.SignContract")}
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        size="small"
                                                                                    >
                                                                                        {t("gettingin.SignContract")}
                                                                                    </Button>
                                                                                </>
                                                                            )}
                                                                        </Typography>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <Typography>
                                                                    {t("gettingin.RequiredInfoDone")} <FontAwesomeIcon style={{ color: "green" }} icon="check" />
                                                                </Typography>
                                                            )}

                                                            <Grid container spacing={2}>
                                                            <Grid item xs={12} md={12} lg={12}>
                                                    {this.state.booking.wifiName && this.state.booking.wifiPass && (
                                                                    <>
                                                                    <Typography variant="h6" paragraph>
                                                                            {t("titles.WiFi")}
                                                                                 </Typography>
                                                                        <Typography variant="body1" paragraph>
                                                                            {t("wifi.WifiName")} : {this.state.booking.wifiName}
                                                                        </Typography>
                                                                        <Typography variant="body1" paragraph>
                                                                            {t("wifi.WifiPassword")} : {this.state.booking.wifiPass}
                                                                        </Typography>                                                    
                                                                    </>
                                                                )}
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container spacing={3}>
                                                                {this.state.checkin.hasSmartLock > 1 && (
                                                                    <Grid item xs={12} md={12} lg={12}>
                                                                        <div>
                                                                             <Typography style={{ marginTop: 15 }} variant="h6" paragraph>
                                                                                {t("gettingin.ShareKey")}
                                                                            </Typography>
                                                                            <Typography style={{ marginTop: 15 }} variant="h6" paragraph>
                                                                                {t("gettingin.BuildingDoor")}
                                                                            </Typography>
                                                                            <Typography variant="body1" paragraph>
                                                                            {this.state.checkin.ringBellForSmartLock == 1 ?
                                                                                <b>{t("gettingin.BuildingDoorNotice", { floor: this.state.checkin.floor, door: this.state.checkin.door })}</b>
                                                                                : <b>{t("gettingin.NoRingBuildingDoorNotice", { floor: this.state.checkin.floor, door: this.state.checkin.door })}</b>
                                                                            }
                                                                            </Typography>
                                                                            <div>
                                                                                <IconButton
                                                                                    onClick={this.unlockBuilding}
                                                                                    className={classes.button}
                                                                                    aria-label={t("gettingin.BuildingDoor")}
                                                                                    disabled={this.state.unlockingBuilding || !this.state.checkin.hasDoneRequired}
                                                                                >
                                                                                    {!this.state.unlockingBuilding ? (
                                                                                        <UnlockIcon
                                                                                            className={
                                                                                                !Boolean(this.state.checkin.hasDoneRequired)
                                                                                                    ? classes.disabled
                                                                                                    : classes[this.state.unlockStateBuilding]
                                                                                            }
                                                                                            style={{
                                                                                                fontSize: 110
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <CircularProgress size={110} className={classes.progress} />
                                                                                    )}
                                                                                </IconButton>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                )}

                                                                {this.state.checkin.hasSmartLock > 0 && this.state.checkin.hasSmartLock < 3 && (
                                                                    <Grid item xs={12} md={12} lg={12}>
                                                                        <div>
                                                                            <Typography variant="h6" paragraph>
                                                                                {t("gettingin.ApartmentDoor")}
                                                                            </Typography>
                                                                            <Typography variant="body1" paragraph>
                                                                                {this.state.checkin.pauClarisStarwayNum === 1 ?
                                                                                    <mark><b>{t("gettingin.StaircasePauclaris1")}</b></mark>
                                                                                    : (this.state.checkin.pauClarisStarwayNum === 2 ?
                                                                                        <mark><b>{t("gettingin.StaircasePauclaris2")}</b></mark>
                                                                                        : null)
                                                                                }
                                                                            </Typography>
                                                                            <Typography variant="body1" paragraph>
                                                                                <b>{t("gettingin.ApartmentDoorNotice", { floor: this.state.checkin.floor, door: this.state.checkin.door })}</b>
                                                                            </Typography>
                                                                            <div>
                                                                                <IconButton
                                                                                    onClick={this.unlockApt}
                                                                                    className={classes.button}
                                                                                    aria-label={t("gettingin.ApartmentDoor")}
                                                                                    disabled={this.state.unlockingApt || !this.state.checkin.hasDoneRequired}
                                                                                >
                                                                                    {!this.state.unlockingApt ? (
                                                                                        <UnlockIcon
                                                                                            className={
                                                                                                !Boolean(this.state.checkin.hasDoneRequired) ? classes.disabled : classes[this.state.unlockStateApt]
                                                                                            }
                                                                                            style={{
                                                                                                fontSize: 110
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <CircularProgress size={110} className={classes.progress} />
                                                                                    )}
                                                                                </IconButton>
                                                                            </div>
                                                                            {this.state.checkin.smartlockPin > 0 ? (
                                                                            <Typography variant="body1" paragraph>
                                                                                <b>{t("gettingin.SmartLockPinMessage", { smartlockPin: this.state.checkin.smartlockPin })}</b>
                                                                            </Typography>): (" ")}

                                                                            {this.state.checkin.numKeys > 0 ? (
                                                                                <>
                                                                            <Typography variant="h6" paragraph>
                                                                                {t("gettingin.InsideApartment")}
                                                                            </Typography>
                                                                            <Typography variant="body1" paragraph>
                                                                                <b>{t("gettingin.KeysRemainder")}</b>
                                                                            </Typography>
                                                                            </> ): (" ")}
                                                                        </div>
                                                                    </Grid>
                                                                )}
                                                            </Grid>

                                                        </>
                                                    )}
                                                    
                                                    <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {this.state.isLoading ? (
                                        <CircularProgress className={classes.progress} />
                                    ) : (
                                        <>
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    {this.state.checkin.checkinProcedureId !== 34 && (
                                                        <>
                                                            <div>
                                                                <Button onClick={this.markOnWay} variant="contained" color="primary">
                                                                    {t("gettingin.MarkOnWay")}
                                                                </Button>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <TimePicker
                                                                        autoOk
                                                                        ampm={false}
                                                                        minutesStep={5}
                                                                        locale={moment.locale(getLanguage())}
                                                                        onChange={this.setDelay}
                                                                        format="HH:mm"
                                                                        fullWidth={true}
                                                                        t={t}
                                                                        TextFieldComponent={DatePickerTextField}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                                <Button onClick={this.openMessageBox} style={{ marginLeft: 10 }} variant="contained" color="primary">
                                                                    {t("gettingin.ContactAgent")}
                                                                </Button>
                                                            </div>
                                                            {this.state.messageBoxOpen && (
                                                                <div className={classes.messageBox}>
                                                                    <Typography variant="h6" paragraph>
                                                                        {t("gettingin.ContactAgent")}
                                                                    </Typography>
                                                                    <TextField
                                                                        vale={this.state.messageContent}
                                                                        onChange={this.handleChange}
                                                                        placeholder={t("gettingin.WriteMessage")}
                                                                        multiline
                                                                        fullWidth
                                                                    ></TextField>
                                                                    <br />
                                                                    <Button onClick={this.sendMessage} disabled={this.state.isSending} style={{ marginTop: 10 }} variant="contained" color="primary">
                                                                        {t("gettingin.Send")}
                                                                    </Button>
                                                                </div>
                                                            )}                                                                                                                

                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                            <AlertDialog
                                open={this.state.open}
                                title={this.state.title}
                                content={this.state.content}
                                close={this.handleClose}
                                buttons={
                                    <Button onClick={this.handleClose} color="primary" autoFocus>
                                        {t("arrival.Close")}
                                    </Button>
                                }
                            />
                        </Paper>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withSnackbar(withStyles(styles)(GettingIn))));
