import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from "react-multi-lang";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import { Field } from "react-final-form";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: "White"
    }
});

const IfCondition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

class Type extends React.Component {
    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("checkin.Required"));

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("guide.TypeDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                    <IfCondition when="segment" is={1}>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 1} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 1 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/baby.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FamilyType1")}
                                                title={t("guide.FamilyType1")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FamilyType1")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FamilyType1Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 2} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 2 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/child.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FamilyType2")}
                                                title={t("guide.FamilyType2")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FamilyType2")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FamilyType2Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 3} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 3 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/teenagers.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FamilyType3")}
                                                title={t("guide.FamilyType3")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FamilyType3")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FamilyType3Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 4} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 4 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/adults.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FamilyType4")}
                                                title={t("guide.FamilyType4")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FamilyType4")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FamilyType4Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                    </IfCondition>

                    <IfCondition when="segment" is={2}>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 1} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 1 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/groupoffriends1825.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsType1")}
                                                title={t("guide.FriendsType1")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FriendsType1")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FreindsType1Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 2} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 2 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/groupoffriends2640.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsType2")}
                                                title={t("guide.FriendsType2")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FriendsType2")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FriendsType2Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 3} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 3 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/groupoffriends4165.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsType3")}
                                                title={t("guide.FriendsType3")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FriendsType3")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FriendsType3Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 4} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 4 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/groupoffriends66.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsType4")}
                                                title={t("guide.FriendsType4")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.FriendsType4")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.FriendsType4Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                    </IfCondition>
                    <IfCondition when="segment" is={3}>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 1} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 1 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/honeymoon.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.FriendsType1")}
                                                title={t("guide.FriendsType1")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.CoupleType1")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.CoupleType1Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 2} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 2 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/parentsawayfromkids.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.CoupleType2")}
                                                title={t("guide.CoupleType2")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.CoupleType2")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.CoupleType2Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 3} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 3 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/youngcouple.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.CoupleType3")}
                                                title={t("guide.CoupleType3")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.CoupleType3")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.CoupleType3Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Field name="type" parse={() => 4} validate={required}>
                                {({ input, meta }) => (
                                    <Card className={input.value === 4 ? classes.selected : ""}>
                                        <CardActionArea onClick={input.onChange}>
                                            <CardMedia
                                                component="img"
                                                image="https://assets.stay-u-nique.com/journey/types/oldtimeadvent.png"
                                                height="250"
                                                width="750"
                                                alt={t("guide.CoupleType4")}
                                                title={t("guide.CoupleType4")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("guide.CoupleType4")}
                                                    </Typography>
                                                    <Typography variant="body2">{t("guide.CoupleType4Desc")}</Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )}
                            </Field>
                        </Grid>
                    </IfCondition>
                </Grid>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(Type));
