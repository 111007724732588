import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-multi-lang";

import Checkbox from "../fields/Checkbox";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

class ExtrasForm extends React.Component {
    render() {
        const { t, classes, values } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("arrival.ExtrasDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(ExtrasForm));
