import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import { withTranslation } from "react-multi-lang";

import arrayMutators from "final-form-arrays";

const updateValue = ([name, newvalue], state, { changeValue }) => {
    changeValue(state, name, value => newvalue);
};

class Wizard extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    };
    static Page = ({ children, mutators }) => {
        if (typeof children === "function") {
            return children(mutators);
        }

        return children;
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues || {}
        };
    }

    next = values => {
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));
    };

    previous = () => {
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));
    };

    validate = values => {
        const children = this.props.children.filter(val => val.props.visible);

        const activePage = React.Children.toArray(children)[this.state.page];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = values => {
        const { onSubmit } = this.props;
        const children = this.props.children.filter(val => val.props.visible);
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values);
        } else {
            this.next(values);
        }
    };

    render() {
        const { t } = this.props;
        const children = this.props.children.filter(val => val.props.visible);
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;
        const steps = React.Children.toArray(children);

        return (
            <div>
                <Hidden smDown>
                    <Stepper
                        activeStep={page}
                        style={{
                            boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)",
                            borderRadius: 4
                        }}
                    >
                        {steps.map(label => (
                            <Step key={label.props.title}>
                                <StepLabel>{label.props.title}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Hidden>

                {steps[page] && (
                    <Hidden mdUp>
                        <Typography variant="h6">{steps[page].props.title}</Typography>
                    </Hidden>
                )}

                <Form
                    initialValues={values}
                    validate={this.validate}
                    onSubmit={this.handleSubmit}
                    mutators={{
                        ...arrayMutators,
                        updateValue
                    }}
                >
                    {({ handleSubmit, submitting, invalid, form: { mutators } }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            {<activePage.type {...activePage.props} mutators={mutators} />}

                            <div className="buttons">
                                {page > 0 && (
                                    <Button onClick={this.previous} id="previous" disabled={submitting} style={{ marginRight: 5 }} variant="contained" color="primary">
                                        {t("guide.Previous")}
                                    </Button>
                                )}
                                {!isLastPage && (
                                    <Button type="submit" id="next" variant="contained" color="primary" disabled={invalid} style={{ marginRight: 5 }}>
                                        {t("guide.Next")}
                                    </Button>
                                )}
                                {isLastPage && (
                                    <Button id="complete" type="submit" disabled={submitting || invalid} variant="contained" color="primary">
                                        {t("guide.Complete")}
                                    </Button>
                                )}
                            </div>
                        </form>
                    )}
                </Form>
            </div>
        );
    }
}

export default withTranslation(Wizard);
