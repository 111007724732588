import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import { withSnackbar } from "notistack";

import Footer from "./Footer";
import Logo from "./Logo";

import { withTranslation } from "react-multi-lang";

import AuthService from "../components/auth/AuthService";

const styles = theme => ({
    "@global": {
        body: {
            backgroundImage: "url(https://assets.stay-u-nique.com/journey/login_no_logo.png)",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundSize: "cover"
        }
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
        background: "linear-gradient(45deg, #53b7ff 30%, #01528c 90%)",
        border: 0,
        borderRadius: 3,
        color: "white",
        height: 48,
        padding: "0 30px"
    }
});

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lastName: "",
            refId: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.Auth = new AuthService();
    }

    UNSAFE_componentWillMount() {
        const params = new URLSearchParams(this.props.location.search);
        const lastName = params.get("lastName");
        const refId = params.get("refId");

        if (lastName && refId) {
            this.setState({ lastName: lastName, refId: refId });

            this.Auth.login(lastName, refId)
                .then(res => {
                    this.props.history.replace("/");
                })
                .catch(err => {
                    this.props.enqueueSnackbar(this.props.t("login.LoginError"), { variant: "error", preventDuplicate: true });
                });
        }
    }

    componentDidMount() {
        if (this.Auth.loggedIn()) {
            this.props.history.replace("/");
        }
    }

    handleFormSubmit(e) {
        e.preventDefault();

        this.Auth.login(this.state.lastName, this.state.refId)
            .then(res => {
                this.props.history.replace("/");
            })
            .catch(err => {
                this.props.enqueueSnackbar(this.props.t("login.LoginError"), { variant: "error", preventDuplicate: true });
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        const { classes, t } = this.props;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Logo color="#4e5e9e" />
                    <Footer margin="5" />
                    <form className={classes.form} noValidate autoComplete="off" onSubmit={event => this.handleFormSubmit(event)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label={t("login.LastName")}
                            name="lastName"
                            autoComplete="lastName"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="refId"
                            label={t("login.Password")}
                            id="refId"
                            autoComplete="refId"
                            value={this.state.refId}
                            onChange={this.handleChange}
                        />
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            {t("login.Login")}
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withTranslation(withSnackbar(withStyles(styles)(Login)));
