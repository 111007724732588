import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Rating from "@material-ui/lab/Rating";
import Button from "@material-ui/core/Button";
import TextField from "./fields/TextField";

import fetchClient from "../components/utils/fetchClient";

import withAuth from "../components/auth/withAuth";

import Checkbox from "./fields/Checkbox";

import { Form, Field, FormSpy } from "react-final-form";

import { withTranslation } from "react-multi-lang";

import Footer from "./Footer";
import Navbar from "./Navbar";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    formControl: {
        marginTop: 5,
        marginBottom: 20
    }
});

const starRating = ({ input, label, disabled }) => (
    <div>
        <Typography>{label}</Typography>
        <Rating size="large" name={input.name} onChange={input.onChange} max={4} value={parseInt(input.value)} disabled={disabled} />
    </div>
);

class Rate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isDisabled: false,
            rating: [],
            highRating: false,
            canComplete: false,
            didComplete: false,
        };

        this.saveChanges = this.saveChanges.bind(this);
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Review");

        let rating = await this.fetchReview();

        this.setState({
            rating: rating,
            isLoading: false,
            isDisabled: Boolean(rating.isComplete),
            canComplete: Boolean(rating.canComplete),
        });
    }

    calculateHighReview(values) {
        const cleaning = values.cleaning ? parseInt(values.cleaning) * 2.5 : 0;
        const staff = values.staff ? parseInt(values.staff) * 2.5 : 0;
        const facilities = values.facilities ? parseInt(values.facilities) * 2.5 : 0;
        const location = values.location ? parseInt(values.location) * 2.5 : 0;
        const comfort = values.comfort ? parseInt(values.comfort) * 2.5 : 0;
        const value = values.value ? parseInt(values.value) * 2.5 : 0;

        const total = cleaning + staff + facilities + location + comfort + value;

        let avg = 0;

        if (total > 0) {
            avg = total / 6;

            if (avg >= 8) {
                this.setState({ highRating: true });
            } else {
                this.setState({ highRating: false });
            }
        }
    }

    saveChanges(values) {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("guests/review", values)
            .then(function (response) {
                self.setState({ didComplete: true });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async fetchReview() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("guests/review")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("review.Required"));

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Review")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {this.state.isLoading ? (
                                        <CircularProgress className={classes.progress} />
                                    ) : (
                                        <>
                                            {this.state.canComplete ? (
                                                <>
                                                    {!this.state.didComplete ? (
                                                        <Form
                                                            onSubmit={this.saveChanges}
                                                            initialValues={this.state.rating}
                                                            render={({ handleSubmit, invalid, submitting }) => (
                                                                <form onSubmit={handleSubmit}>
                                                                    <FormSpy
                                                                        subscription={{ values: true, valid: true }}
                                                                        onChange={state => {
                                                                            const { values } = state;
                                                                            this.calculateHighReview(values);
                                                                        }}
                                                                    />
                                                                    <Field
                                                                        fullWidth
                                                                        name="staff"
                                                                        label={
                                                                            this.state.rating.agentNames.length
                                                                                ? t("review.RateStaff") + " (" + this.state.rating.agentNames + ")"
                                                                                : t("review.RateStaff")
                                                                        }
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        validate={required}
                                                                    />

                                                                    <Field
                                                                        fullWidth
                                                                        name="cleaning"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={t("review.RateCleanliness")}
                                                                        validate={required}
                                                                    />
                                                                    <Field
                                                                        fullWidth
                                                                        name="location"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={t("review.RateLocation")}
                                                                        validate={required}
                                                                    />
                                                                    <Field
                                                                        fullWidth
                                                                        name="facilities"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={t("review.RateFacilities")}
                                                                        validate={required}
                                                                    />
                                                                    <Field
                                                                        fullWidth
                                                                        name="comfort"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={t("review.RateComfort")}
                                                                        validate={required}
                                                                    />
                                                                    <Field
                                                                        fullWidth
                                                                        name="value"
                                                                        disabled={this.state.isDisabled}
                                                                        component={starRating}
                                                                        type="text"
                                                                        label={t("review.RateValue")}
                                                                        validate={required}
                                                                    />
                                                                    <Field
                                                                        name="customerFeedback"
                                                                        disabled={this.state.isDisabled}
                                                                        label={t("review.Improvement")}
                                                                        className={classes.formControl}
                                                                        multiline
                                                                        fullWidth
                                                                        component={TextField}
                                                                    />
                                                                    <Field name="recommended" disabled={this.state.isDisabled} type="checkbox" label={t("review.Recommended")} component={Checkbox} />

                                                                    <Button variant="contained" type="submit" color="primary" disabled={invalid || submitting || this.state.isDisabled}>
                                                                        {t("review.Submit")}
                                                                    </Button>
                                                                </form>
                                                            )}
                                                        />
                                                    ) : (
                                                        <>
                                                            <Typography paragraph>{t("review.SubmitSuccess")}</Typography>

                                                            {this.state.highRating && (
                                                                <>
                                                                    <Typography style={{ whiteSpace: "break-spaces" }} paragraph>
                                                                        <span dangerouslySetInnerHTML={{ __html: t("review.HighReview") }} />
                                                                    </Typography>
                                                                    <Button variant="contained" color="primary" href={"https://g.page/StayU-nique/review?gm"} target="_blank">
                                                                        Google
                                                                    </Button>
                                                                    <Button
                                                                        style={{ marginTop: 10 }}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        href={"https://trustpilot.com/review/www.rentthesun.com"}
                                                                        target="_blank"
                                                                    >
                                                                        Trustpilot
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <Typography>{t("review.Unavailable")}</Typography>
                                            )}
                                        </>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Rate)));
