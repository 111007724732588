import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ArrivalIcon from "@material-ui/icons/AccessTime";
import BookingIcon from "@material-ui/icons/ConfirmationNumber";
import InfoIcon from "@material-ui/icons/Info";
import ApartmentIcon from "@material-ui/icons/Home";
import SmartLockIcon from "@material-ui/icons/LockOpen";
import ActivitiesIcon from "@material-ui/icons/LocalActivity";
import BookIcon from "@material-ui/icons/Book";
import RateIcon from "@material-ui/icons/RateReview";
import ReviewIcon from "@material-ui/icons/Star";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import IssueIcon from "@material-ui/icons/ReportProblem";
import WifiIcon from "@material-ui/icons/Wifi";
import ContractsIcon from "@material-ui/icons/Description";
import ChatIcon from "@material-ui/icons/Chat";

import { withRouter } from "react-router";

import { withTranslation } from "react-multi-lang";

import { Link } from "react-router-dom";

import AuthService from "../components/auth/AuthService";

import ServicesIcon from "../assets/services.png";

const Auth = new AuthService();

class NavItems extends React.Component {
    logout() {
        Auth.logout();
        this.props.history.replace("/login");
    }

    render() {
        const { t, currentPath } = this.props;

        return (
            <div>
                {Auth.loggedIn() ? (
                    <>
                        <ListItem button component={Link} to="/your-booking" selected={currentPath === "/your-booking" ? true : false}>
                            <ListItemIcon>
                                <BookingIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText style={{ color: "gold" }} primary={t("nav.YourBooking")} />
                        </ListItem>
                        <ListItem button component={Link} to="/arrival" selected={currentPath === "/arrival" ? true : false}>
                            <ListItemIcon>
                                <ArrivalIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText style={{ color: "gold" }} primary={t("nav.BeforeArrival")} />
                        </ListItem>

                        <ListItem button component={Link} to="/getting-in" selected={currentPath === "/getting-in" ? true : false}>
                            <ListItemIcon>
                                <SmartLockIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText style={{ color: "gold" }} primary={t("nav.GettingIn")} />
                        </ListItem>

                        <ListItem button component={Link} to="/your-apartment" selected={currentPath === "/your-apartment" ? true : false}>
                            <ListItemIcon>
                                <ApartmentIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.YourApartment")} />
                        </ListItem>

                        <ListItem button component={Link} to="/contracts" selected={currentPath === "/contracts" ? true : false}>
                            <ListItemIcon>
                                <ContractsIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Contracts")} />
                        </ListItem>

                        <ListItem button component={Link} to="/wifi" selected={currentPath === "/wifi" ? true : false}>
                            <ListItemIcon>
                                <WifiIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.WiFi")} />
                        </ListItem>

                        <ListItem button component={Link} to="/chat" selected={currentPath === "/chat" ? true : false}>
                            <ListItemIcon>
                                <ChatIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Chat")} />
                        </ListItem>

                        <ListItem button component={Link} to="/services" selected={currentPath === "/services" ? true : false}>
                            <ListItemIcon>
                                <img height="22" src={ServicesIcon} alt={t("nav.Services")} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Services")} />
                        </ListItem>

                        <ListItem button component={Link} to="/activities" selected={currentPath === "/activities" ? true : false}>
                            <ListItemIcon>
                                <ActivitiesIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Activities")} />
                        </ListItem>

                        <ListItem button component={Link} to="/rate" selected={currentPath === "/rate" ? true : false}>
                            <ListItemIcon>
                                <RateIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Rate")} />
                        </ListItem>

                        <ListItem button component={Link} to="/issue" selected={currentPath === "/issue" ? true : false}>
                            <ListItemIcon>
                                <IssueIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Issue")} />
                        </ListItem>

                        <ListItem button component={Link} to="/your-guide" selected={currentPath === "/your-guide" ? true : false}>
                            <ListItemIcon>
                                <BookIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Guide")} />
                        </ListItem>

                        <ListItem button component={Link} to="/review" selected={currentPath === "/review" ? true : false}>
                            <ListItemIcon>
                                <ReviewIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Review")} />
                        </ListItem>

                        <ListItem button component={Link} to="/about" selected={currentPath === "/about" ? true : false}>
                            <ListItemIcon>
                                <InfoIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.About")} />
                        </ListItem>
                        <Divider />

                        <ListItem button onClick={() => this.logout()}>
                            <ListItemIcon>
                                <LogoutIcon style={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary={t("nav.Logout", { param: Auth.getProfile().customerName.split(" ")[0] })} />
                        </ListItem>
                    </>
                ) : (
                    <ListItem button component={Link} to="/login">
                        <ListItemIcon>
                            <LogoutIcon style={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText primary={t("nav.Login")} />
                    </ListItem>
                )}
            </div>
        );
    }
}

export default withRouter(withTranslation(NavItems));
