import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "./fields/TextField";

import fetchClient from "../components/utils/fetchClient";

import Carousel, { Modal, ModalGateway } from "react-images";

import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";

import { withTranslation } from "react-multi-lang";

import withAuth from "../components/auth/withAuth";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import ImageCompressor from "uppy-plugin-image-compressor";

import Footer from "./Footer";
import Navbar from "./Navbar";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    formControl: {
        marginTop: 5,
        marginBottom: 20
    }
});

const updateValue = ([name, newvalue], state, { changeValue }) => {
    changeValue(state, name, value => newvalue);
};

class renderFileUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            lightboxOpen: false,
            files: [],
            photoIndex: 0
        };

        this.openLightbox = this.openLightbox.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.fileuploader = new Uppy({ id: "issue", autoProceed: true })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "issues",
                                upload_type: "issue",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.props.mutators.updateValue("issueFiles", [...this.props.input.value, resp.uploadURL]);
            });
    }

    componentWillUnmount() {
        this.fileuploader.close();
    }

    openLightbox(files, index) {
        this.setState({
            lightboxOpen: true,
            files: files,
            photoIndex: index
        });
    }

    closeLightbox() {
        this.setState({
            lightboxOpen: false,
            files: [],
            photoIndex: 0
        });
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <DragDrop
                    uppy={this.fileuploader}
                    locale={{
                        strings: {
                            dropHereOr: t("issue.Upload") + " %{browse}",
                            browse: t("issue.Browse")
                        }
                    }}
                />
                <StatusBar uppy={this.fileuploader} hideUploadButton hideAfterFinish={false} showProgressDetails />

                <div style={{ paddingBottom: 10 }}>
                    {this.props.input.value
                        ? this.props.input.value.map((image, index) => (
                              <img
                                  key={index}
                                  style={{
                                      paddingLeft: 2,
                                      paddingRight: 2
                                  }}
                                  height="50"
                                  width="50"
                                  className="uploaded-image"
                                  src={image}
                                  alt={image.split("/").pop()}
                                  onClick={() => this.openLightbox(this.props.input.value, index)}
                              />
                          ))
                        : null}
                </div>
                <ModalGateway>
                    {this.state.lightboxOpen ? (
                        <Modal
                            onClose={this.closeLightbox}
                            styles={{
                                blanket: (base, state) => ({ ...base, zIndex: 1202 }),
                                positioner: (base, state) => ({ ...base, zIndex: 1212 }),
                                dialog: (base, state) => ({ ...base, zIndex: 1222 })
                            }}
                        >
                            <Carousel
                                currentIndex={this.state.photoIndex}
                                views={this.props.input.value.map(file => ({
                                    src: file
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        );
    }
}

class Issue extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isDisabled: false,
            didSubmit: false
        };

        this.reportIssue = this.reportIssue.bind(this);
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Issue");

        this.setState({
            isLoading: false
        });
    }

    reportIssue(values) {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("guests/issue", values)
            .then(function (response) {
                self.setState({ didSubmit: true });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("issue.Required"));

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Issue")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {!this.state.didSubmit ? (
                                        <Form
                                            onSubmit={this.reportIssue}
                                            mutators={{
                                                ...arrayMutators,
                                                updateValue
                                            }}
                                            render={({ handleSubmit, invalid, submitting, form: { mutators } }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <Field
                                                        fullWidth
                                                        name="issueContent"
                                                        component={TextField}
                                                        className={classes.formControl}
                                                        type="text"
                                                        label={t("issue.Description")}
                                                        validate={required}
                                                    />

                                                    <Field t={t} classes={classes} mutators={mutators} component={renderFileUploader} name="issueFiles" />

                                                    <Button variant="contained" type="submit" color="primary" disabled={invalid || submitting || this.state.isDisabled}>
                                                        {t("issue.Report")}
                                                    </Button>
                                                </form>
                                            )}
                                        />
                                    ) : (
                                        <Typography>{t("issue.ReportSuccess")}</Typography>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Issue)));
