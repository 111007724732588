import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-multi-lang";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

class Finish extends React.Component {
    restart() {
        localStorage.removeItem("guide");
        window.location.reload();
    }

    render() {
        const { t, guideUrl, classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Typography paragraph variant="h5">
                                {t("guide.Finish")}
                            </Typography>
                            <Typography paragraph variant="body1">
                                {t("guide.FinishMessage")}
                            </Typography>

                            <Button href={guideUrl} variant="contained" target="_blank" color="primary">
                                {t("guide.GuideDownload")}
                            </Button>

                            <Button onClick={this.restart} variant="contained" style={{ marginTop: 10 }} color="secondary">
                                {t("guide.GuideRestart")}
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(Finish));
