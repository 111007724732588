import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-multi-lang";

import Footer from "../Footer";
import Navbar from "../Navbar";

import fetchClient from "../utils/fetchClient";

import withAuth from "../../components/auth/withAuth";

import Wizard from "./Wizard";

import Segment from "./Segment";
import Type from "./Type";
import TouristType from "./TouristType";
import Reason from "./Reason";
import Finish from "./Finish";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    card: {
        background: theme.palette.secondary.main,
        margin: theme.spacing(2)
    },
    button: {
        width: "100%"
    }
});

class Guide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            guideDone: false,
            guideUrl: ""
        };

        this.getGuide = this.getGuide.bind(this);


        var guide = JSON.parse(localStorage.getItem("guide"));
        if (guide) {
            Object.keys(guide).forEach(key => {
                this.state[key] = guide[key];
            });
        }
    }
    componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Guide");
    }

    getGuide(values) {
        var self = this;

        fetchClient
            .post("guests/guide", values)
            .then(function(response) {
                if (response.data.success) {
                    localStorage.setItem("guide",JSON.stringify({guideUrl:response.data.data.guideLink, guideDone: true }));
                    self.setState({ guideDone: true, guideUrl: response.data.data.guideLink });
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Guide")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    <>
                                        {!this.state.guideDone ? (
                                            <Wizard onSubmit={this.getGuide}>
                                                <Wizard.Page title={t("guide.Segment")} visible={true}>
                                                    <Segment />
                                                </Wizard.Page>
                                                <Wizard.Page title={t("guide.Type")} visible={true}>
                                                    <Type />
                                                </Wizard.Page>
                                                <Wizard.Page title={t("guide.TouristType")} visible={true}>
                                                    <TouristType />
                                                </Wizard.Page>
                                                <Wizard.Page title={t("guide.Reason")} visible={true}>
                                                    <Reason />
                                                </Wizard.Page>
                                            </Wizard>
                                        ) : (
                                            <Finish guideUrl={this.state.guideUrl} />
                                        )}
                                    </>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Guide)));
