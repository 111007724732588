import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { setDefaultTranslations, setDefaultLanguage, setLanguage } from "react-multi-lang";

import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import TagManager from "react-gtm-module";

import es from "./translations/es.json";
import en from "./translations/en.json";

const tagManagerArgs = {
    gtmId: "GTM-KTQPFV3"
};

TagManager.initialize(tagManagerArgs);

setDefaultTranslations({ es, en });
setDefaultLanguage("en");

var guestAppSettings = JSON.parse(localStorage.getItem("guestAppSettings"));
if (guestAppSettings) {
    if (guestAppSettings.language) {
        setLanguage(guestAppSettings.language);
    }
} else {
    localStorage.setItem("guestAppSettings", JSON.stringify({ language: "en" }));
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
    <SnackbarProvider
        ref={notistackRef}
        action={key => (
            <IconButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
                <CloseIcon />
            </IconButton>
        )}
        anchorOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
    >
        <App />
    </SnackbarProvider>,
    document.getElementById("root")
);
