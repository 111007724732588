import * as React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import convertToBooleanValue from "../../components/utils/formUtilities";

const CheckboxWrapper = ({ input: { checked, name, onChange, ...restInput }, meta, label, ...rest }) => {
    let val = convertToBooleanValue(checked);

    return <FormControlLabel control={<Checkbox {...rest} name={name} inputProps={restInput} onChange={onChange} checked={val} />} label={label} />;
};

export default CheckboxWrapper;
