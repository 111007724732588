import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link";
import { Field } from "react-final-form";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withTranslation } from "react-multi-lang";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import fetchClient from "../utils/fetchClient";

const CustomTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 12
    }
}))(Tooltip);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 14
    },
    body: {
        fontSize: 14
    }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.default
        }
    }
}))(TableRow);

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    summary: {
        backgroundColor: theme.palette.secondary.main + " !important"
    },
    CustomTooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9"
    }
});

const MoreThanCondition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (parseInt(value) > parseInt(is) ? children : null)}
    </Field>
);

const IfCondition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (parseInt(value) === parseInt(is) ? children : null)}
    </Field>
);

function TextOnly(props) {
    let val = props.input.value;
    return <span {...props}>€{val}</span>;
}

class PaymentsForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            paymentDialogOpen: false,
            paymentLink: "",
            tooltips: {
                tax: false,
                rent: false,
                late: false,
                extras: false
            }
        };

        this.createPaymentLink = this.createPaymentLink.bind(this);
        this.handleClosePaymentDialog = this.handleClosePaymentDialog.bind(this);
        this.recheck = this.recheck.bind(this);
    }

    createPaymentLink(desc,amount) {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("guests/payment-link", {amount: amount, description: desc})
            .then(function (response) {
                if (response.data.data.link) {
                    self.setState({ paymentDialogOpen: true, paymentLink: response.data.data.link });
                    window.open(response.data.data.link);

                    self.interval = setInterval(() => self.recheck(), 10000);

                    localStorage.setItem("guestAppStep", "arrival");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async recheck() {
        let results = await this.checkPayments();

        this.props.updateValue("totalToPay", results.totalToPay);
        this.props.updateValue("balanceDue", results.balanceDue);
        this.props.updateValue("totalPaid", results.totalPaid);
    }

    async checkPayments() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("guests/payment-check")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    handleClosePaymentDialog() {
        this.setState({
            paymentDialogOpen: false
        });
    }

    toggleTooltip(name, val) {
        this.setState({ tooltips: { ...this.state.tooltips, [name]: val } });
    }

    render() {
        const { t, classes, values } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("arrival.PaymentsDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{t("arrival.PaymentDesc")}</StyledTableCell>
                                        <StyledTableCell align="right">{t("arrival.PaymentAmount")}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key={1}>
                                        <StyledTableCell component="th" scope="row">
                                            {t("arrival.PaymentRentCleaning")}

                                            <ClickAwayListener onClickAway={() => this.toggleTooltip("rent", false)}>
                                                <CustomTooltip
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    onClose={() => this.toggleTooltip("rent", false)}
                                                    placement="right"
                                                    open={this.state.tooltips["rent"]}
                                                    title={t("arrival.PaymentRentCleaningMessage", {
                                                        rent: values.totalPrice,
                                                        cleaningFee: values.cleaningFee
                                                    })}
                                                    aria-label={t("arrival.PaymentRentCleaning")}
                                                >
                                                    <InfoIcon onClick={() => this.toggleTooltip("rent", true)} color="primary" style={{ fontSize: 20 }} />
                                                </CustomTooltip>
                                            </ClickAwayListener>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Field name="totalRent" component={TextOnly} />
                                            <IfCondition when="rentPaid" is={1}>
                                                <FontAwesomeIcon
                                                    style={{
                                                        paddingLeft: 5,
                                                        color: "green"
                                                    }}
                                                    icon="check"
                                                />
                                            </IfCondition>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow key={2}>
                                        <StyledTableCell component="th" scope="row">
                                            {t("arrival.PaymentTax")}
                                            <ClickAwayListener onClickAway={() => this.toggleTooltip("tax", false)}>
                                                <CustomTooltip
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    onClose={() => this.toggleTooltip("tax,", false)}
                                                    placement="right"
                                                    open={this.state.tooltips["tax"]}
                                                    title={t("arrival.PaymentTaxMessage", {
                                                        taxAmount: values.touristTaxValue
                                                    })}
                                                    aria-label={t("arrival.PaymentTax")}
                                                >
                                                    <InfoIcon onClick={() => this.toggleTooltip("tax", true)} color="primary" style={{ fontSize: 20 }} />
                                                </CustomTooltip>
                                            </ClickAwayListener>
                                            <a target="_blank" rel="noopener noreferrer" href="https://stay-u-nique.com/information/city-tax">
                                                <HelpIcon color="primary" style={{ fontSize: 20 }} />
                                            </a>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Field name="touristTaxEurVat" component={TextOnly} />
                                            <IfCondition when="taxPaid" is={1}>
                                                <FontAwesomeIcon
                                                    style={{
                                                        paddingLeft: 5,
                                                        color: "green"
                                                    }}
                                                    icon="check"
                                                />
                                            </IfCondition>
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <MoreThanCondition when="lateArrivalPrice" is={0}>
                                        <StyledTableRow key={3}>
                                            <StyledTableCell component="th" scope="row">
                                                {t("arrival.PaymentLateFee")}

                                                <ClickAwayListener onClickAway={() => this.toggleTooltip("late", false)}>
                                                    <CustomTooltip
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        onClose={() => this.toggleTooltip("late,", false)}
                                                        placement="right"
                                                        open={this.state.tooltips["late"]}
                                                        title={t("arrival.PaymentLateMessage")}
                                                        aria-label={t("arrival.PayPaymentLateFeementTax")}
                                                    >
                                                        <InfoIcon onClick={() => this.toggleTooltip("late", true)} color="primary" style={{ fontSize: 20 }} />
                                                    </CustomTooltip>
                                                </ClickAwayListener>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Field name="lateArrivalPrice" component={TextOnly} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </MoreThanCondition>

                                    <MoreThanCondition when="extrasPrice" is={0}>
                                        <StyledTableRow key={5}>
                                            <StyledTableCell component="th" scope="row">
                                                {t("arrival.PaymentExtras")}

                                                <ClickAwayListener onClickAway={() => this.toggleTooltip("extras", false)}>
                                                    <CustomTooltip
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                        onClose={() => this.toggleTooltip("extras", false)}
                                                        placement="right"
                                                        open={this.state.tooltips["extras"]}
                                                        title={t("arrival.PaymentExtraMessage")}
                                                        aria-label={t("arrival.PaymentExtras")}
                                                    >
                                                        <InfoIcon onClick={() => this.toggleTooltip("extras", true)} color="primary" style={{ fontSize: 20 }} />
                                                    </CustomTooltip>
                                                </ClickAwayListener>
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Field name="extrasPrice" component={TextOnly} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </MoreThanCondition>
                                    <StyledTableRow key={6}>
                                        <StyledTableCell component="th" scope="row">
                                            {t("arrival.PaymentTotalToPay")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Field name="totalToPay" component={TextOnly} />
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow key={7}>
                                        <StyledTableCell component="th" scope="row">
                                            {t("arrival.PaymentTotalPaid")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Field name="totalPaid" component={TextOnly} />
                                        </StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow key={8} className={classes.summary}>
                                        <StyledTableCell component="th" scope="row" style={{ color: "red" }}>
                                            {t("arrival.PaymentBalanceDue")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Field name="balanceDue" component={TextOnly} style={{ color: "red" }} />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>

                            <MoreThanCondition when="balanceDue" is={0}>
                                <Button style={{ marginTop: 10 }} variant="contained" color="primary" onClick={() => this.createPaymentLink("amount",values.balanceDue)}>
                                    {t("arrival.PayBalance")}
                                </Button>
                            </MoreThanCondition>

                            <IfCondition when="hasDoneDeposit" is={0}>
                                <Button style={{ marginTop: 10 }} variant="contained" color="primary" onClick={() => this.createPaymentLink("deposit",values.bondRequired)}>
                                    {t("arrival.PayDeposit", {deposit: values.bondRequired})}
                                </Button>
                            </IfCondition>
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={this.state.paymentDialogOpen} onClose={this.handleClosePaymentDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{t("arrival.PaymentTitle")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t("arrival.PaymentLinkDesc")}{" "}
                            <Link href={this.state.paymentLink} target="_blank">
                                {t("arrival.ClickHere")}
                            </Link>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClosePaymentDialog} color="primary" autoFocus>
                            {t("arrival.Close")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(PaymentsForm));
