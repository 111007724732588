import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";

import moment from "moment";

import TextField from "../fields/TextField";
import Select from "../fields/Select";

import fetchClient from "../utils/fetchClient";

import { withTranslation } from "react-multi-lang";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    heading: {
        marginTop: 15
    },
    progress: {
        marginTop: 15
    }
});

class PassportForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            countries: []
        };
    }

    async componentDidMount() {
        let countries = await this.fetchCountries();

        this.setState({
            countries: countries,
            isLoading: false
        });
    }

    async fetchCountries() {
        return new Promise(function(resolve, reject) {
            fetchClient
                .get("availability/countries")
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("arrival.Required"));

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("arrival.PassportDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                {!this.state.isLoading ? (
                    <FieldArray name="guests">
                        {({ fields }) => (
                            <>
                                {fields.map((name, index) => (
                                    <div key={index}>
                                        <Typography className={classes.heading} variant="h5" paragraph>
                                            {t("arrival.Guest")} {index + 1}
                                        </Typography>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={2} lg={2}>
                                                <Paper className={classes.paper}>
                                                    <Field fullWidth name={`${name}.firstName`} component={TextField} type="text" label={t("arrival.FirstName")} validate={required} />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={2} lg={2}>
                                                <Paper className={classes.paper}>
                                                    <Field fullWidth name={`${name}.lastName`} component={TextField} type="text" label={t("arrival.LastName")} validate={required} />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={3}>
                                                <Paper className={classes.paper}>
                                                    <Field fullWidth name={`${name}.birthday`} component={TextField} type="date" label={t("arrival.Birthday")} validate={required} />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={2} lg={2}>
                                                <Paper className={classes.paper}>
                                                    <Field fullWidth name={`${name}.passport`} component={TextField} type="text" label={t("arrival.Passport")} validate={required} />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={3}>
                                                <Paper className={classes.paper}>
                                                    <Field fullWidth native name={`${name}.countryCode`} component={Select} label={t("arrival.Country")} validate={required}>
                                                        {this.state.countries.map((country, index) => (
                                                            <option key={index} value={country.countryId.toUpperCase()}>
                                                                {country.countryName}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </Paper>
                                            </Grid>
                                        </Grid>

                                        <Button type="button" style={{ marginTop: 10 }} variant="contained" color="primary" onClick={() => fields.remove(index)}>
                                            {t("arrival.RemoveGuest")}
                                        </Button>
                                    </div>
                                ))}
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: 10 }}
                                    onClick={() =>
                                        fields.push({
                                            firstName: "",
                                            lastName: "",
                                            passport: "",
                                            birthday: moment().format("YYYY-MM-DD"),
                                            countryCode: "ES"
                                        })
                                    }
                                >
                                    {t("arrival.AddGuest")}
                                </Button>
                            </>
                        )}
                    </FieldArray>
                ) : (
                    <CircularProgress className={classes.progress} />
                )}
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(PassportForm));
