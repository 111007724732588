import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from "react-multi-lang";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";

import { Field } from "react-final-form";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },

    selected: {
        backgroundColor: theme.palette.primary.main,
        color: "White"
    }
});

class Segment extends React.Component {
    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("checkin.Required"));

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("guide.SegmentDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Field name="segment" parse={() => 1} validate={required}>
                            {({ input, meta }) => (
                                <Card className={input.value === 1 ? classes.selected : ""}>
                                    <CardActionArea onClick={input.onChange}>
                                        <CardMedia
                                            component="img"
                                            image="https://assets.stay-u-nique.com/journey/segments/family.png"
                                            height="250"
                                            width="750"
                                            alt={t("guide.FamilyTitle")}
                                            title={t("guide.FamilyTitle")}
                                        />
                                        <CardContent>
                                            <Grid item xs={12} align="center">
                                                <Typography gutterBottom variant="h5" component="h2" align="center">
                                                    {t("guide.FamilyTitle")}
                                                </Typography>
                                                <Typography variant="body2">{t("guide.FamilyDesc")}</Typography>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Field name="segment" parse={() => 2} validate={required}>
                            {({ input, meta }) => (
                                <Card className={input.value === 2 ? classes.selected : ""}>
                                    <CardActionArea onClick={input.onChange}>
                                        <CardMedia
                                            component="img"
                                            image="https://assets.stay-u-nique.com/journey/segments/groupoffriends.png"
                                            height="250"
                                            width="750"
                                            alt={t("guide.GroupOfFriends")}
                                            title={t("guide.GroupOfFriends")}
                                        />
                                        <CardContent>
                                            <Grid item xs={12} align="center"></Grid>
                                            <Typography gutterBottom variant="h5" component="h2" align="center">
                                                {t("guide.FriendsTitle")}
                                            </Typography>

                                            <Typography variant="body2">{t("guide.FriendsDesc")}</Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )}
                        </Field>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Field name="segment" parse={() => 3} validate={required}>
                            {({ input, meta }) => (
                                <Card className={input.value === 3 ? classes.selected : ""}>
                                    <CardActionArea onClick={input.onChange}>
                                        <CardMedia
                                            component="img"
                                            image="https://assets.stay-u-nique.com/journey/segments/couple.png"
                                            height="250"
                                            width="750"
                                            alt={t("guide.Couple")}
                                            title={t("guide.Couple")}
                                        />
                                        <CardContent>
                                            <Grid item xs={12} align="center">
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    {t("guide.CoupleTitle")}
                                                </Typography>
                                                <Typography variant="body2">{t("guide.CoupleDesc")}</Typography>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )}
                        </Field>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(Segment));
