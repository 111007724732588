import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withSnackbar } from "notistack";

import withAuth from "../../components/auth/withAuth";

import fetchClient from "../utils/fetchClient";

import { withRouter } from "react-router";

import { withTranslation } from "react-multi-lang";

import Wizard from "./Wizard";

import PersonalInfoForm from "./PersonalInfoForm";
import ArrivalForm from "./ArrivalForm";
import PassportForm from "./PassportForm";
import PaymentsForm from "./PaymentsForm";
import ContractForm from "./ContractForm";
import ExtrasForm from "./ExtrasForm";

import Footer from "../Footer";
import Navbar from "../Navbar";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column"
    }
});

class Arrival extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            arrival: [],
            page: 0
        };

        this.saveChanges = this.saveChanges.bind(this);

        var guestAppStep = localStorage.getItem("guestAppStep");
        if (guestAppStep) {
            if (guestAppStep === "arrival") {
                this.state.page = 4;
            }

            localStorage.removeItem("guestAppStep");
        }

        if (this.props.match && this.props.match.url.indexOf("passport") !== -1) {
            this.state.page = 1;
        }

        if (this.props.match && this.props.match.url.indexOf("payments-step") !== -1) {
            this.state.page = 4;
        }
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.BeforeArrival");

        let arrival = await this.fetchCheckin();
        this.setState({
            arrival: arrival,
            isLoading: false
        });
    }

    saveChanges(values) {
        var self = this;

        fetchClient
            .post("guests/arrival", values)
            .then(function (response) {
                self.props.enqueueSnackbar(self.props.t("arrival.ChangesSaved"), { preventDuplicate: true, variant: "success" });
            })
            .catch(function (error) {
                self.props.enqueueSnackbar(self.props.t("arrival.SubmitError"), { preventDuplicate: true, variant: "error" });
            });
    }

    async fetchCheckin() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("guests/arrival")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.BeforeArrival")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {this.state.isLoading ? (
                                        <CircularProgress className={classes.progress} />
                                    ) : (
                                        <Wizard page={this.state.page} initialValues={this.state.arrival} onSubmit={this.saveChanges}>
                                            <Wizard.Page visible={true} title={t("arrival.Arrival")}>
                                                <ArrivalForm autoCheckin={this.state.arrival.autoCheckIn} customerName={this.state.arrival.customerFirstName} />
                                            </Wizard.Page>
                                            <Wizard.Page visible={!this.state.arrival.hidePassportForm} title={t("arrival.Passport")}>
                                                 <PassportForm />
                                            </Wizard.Page>
                                            <Wizard.Page visible={true} title={t("arrival.PersonalInformation")}>
                                                <PersonalInfoForm />
                                            </Wizard.Page>
                                            <Wizard.Page visible={false} title={t("arrival.Extras")}>
                                                {({ updateValue }) => <ExtrasForm values={this.state.arrival} updateValue={updateValue} />}
                                            </Wizard.Page>
                                            <Wizard.Page visible={true} title={t("arrival.Payments")}>
                                                {({ updateValue }) => <PaymentsForm values={this.state.arrival} updateValue={updateValue} />}
                                            </Wizard.Page>
                                            <Wizard.Page visible={Boolean(this.state.arrival.needsContract)} title={t("arrival.Contract")}>
                                                {({ updateValue }) => <ContractForm values={this.state.arrival} updateValue={updateValue} />}
                                            </Wizard.Page>
                                        </Wizard>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withRouter(withTranslation(withAuth(withSnackbar(withStyles(styles)(Arrival)))));
