import React from "react";

import styled from "styled-components";

const Spinner = styled.div`
    color: #4e5e9e;
    width: 8rem;
    height: 0;
    padding-bottom: 8rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    will-change: transform;
    animation: spin 1600ms linear normal infinite;
    background-color: transparent;
    clip: rect(0, 4rem, 8rem, 0);

    &::before,
    &::after {
        content: " ";
        height: 100%;
        width: 100%;
        border: 0.5rem solid #4e5e9e;
        border-radius: 50%;
        clip: rect(0, 4rem, 8rem, 0);
        left: 0;
        position: absolute;
        top: 0;
        box-sizing: border-box;
        animation: shape-shift-before 1600ms linear normal infinite;
    }
    @-moz-keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25.01% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(auto, auto, auto, auto);
        }
        50% {
            transform: translate(-50%, -50%) rotate(180deg);
            clip: rect(auto, auto, auto, auto);
        }
        100% {
            transform: translate(-50%, -50%) rotate(730deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
    }
    @-webkit-keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25.01% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(auto, auto, auto, auto);
        }
        50% {
            transform: translate(-50%, -50%) rotate(180deg);
            clip: rect(auto, auto, auto, auto);
        }
        100% {
            transform: translate(-50%, -50%) rotate(730deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
    }
    @-o-keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25.01% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(auto, auto, auto, auto);
        }
        50% {
            transform: translate(-50%, -50%) rotate(180deg);
            clip: rect(auto, auto, auto, auto);
        }
        100% {
            transform: translate(-50%, -50%) rotate(730deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
    }
    @keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
        25.01% {
            transform: translate(-50%, -50%) rotate(90deg);
            clip: rect(auto, auto, auto, auto);
        }
        50% {
            transform: translate(-50%, -50%) rotate(180deg);
            clip: rect(auto, auto, auto, auto);
        }
        100% {
            transform: translate(-50%, -50%) rotate(730deg);
            clip: rect(0, 4rem, 8rem, 0);
        }
    }
    @-moz-keyframes shape-shift-before {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(360deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @-webkit-keyframes shape-shift-before {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(360deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @-o-keyframes shape-shift-before {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(360deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @keyframes shape-shift-before {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(360deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @-moz-keyframes shape-shift-after {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(540deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @-webkit-keyframes shape-shift-after {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(540deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @-o-keyframes shape-shift-after {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(540deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @keyframes shape-shift-after {
        0% {
            transform: rotate(180deg);
        }
        25% {
            transform: rotate(360deg);
        }
        50% {
            transform: rotate(540deg);
        }
        75% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
`;

class Loader extends React.Component {
    render() {
        return <Spinner />;
    }
}

export default Loader;
