import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-multi-lang";

import Footer from "./Footer";
import Navbar from "./Navbar";

import withAuth from "../components/auth/withAuth";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    card: {
        height: "100%"
    },
    button: {
        width: "100%"
    }
});

class Activities extends React.Component {
    componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Activities");
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Activities")}
                        </Typography>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Card className={classes.card}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                className={classes.media}
                                                image="https://assets.stay-u-nique.com/journey/journeybanner.png"
                                                alt={t("activities.ActivitiesTitle")}
                                                title={t("activities.ActivitiesTitle")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("activities.ActivitiesTitle")}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {t("activities.ActivitiesDesc")}
                                                    </Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Grid item xs={12} align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    target="_blank"
                                                    href="https://www.ticketinhotel.es/site/index?token=15951e25ba9c662587bf628ae93db2c234d52cf12fde4ef08521ac793302227d33287b0ead4c344a4c312dd0b8474dbbc68fb873bc08865cc52f72d811204509"
                                                    className={classes.button}
                                                >
                                                    {t("activities.ViewActivities")}
                                                </Button>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    <Card className={classes.card}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                className={classes.media}
                                                image="https://assets.stay-u-nique.com/journey/activitiesbanner.png"
                                                alt={t("activities.BlogTitle")}
                                                title={t("activities.BlogTitle")}
                                            />
                                            <CardContent>
                                                <Grid item xs={12} align="center">
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {t("activities.BlogTitle")}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {t("activities.BlogDesc")}
                                                    </Typography>
                                                </Grid>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <Grid item xs={12} align="center">
                                                <Button variant="contained" color="primary" target="_blank" href="https://barcelona.stay-u-nique.com" className={classes.button}>
                                                    {t("activities.ViewBlog")}
                                                </Button>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Activities)));
