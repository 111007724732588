import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ApartmentIcon from "@material-ui/icons/Home";
import Button from "@material-ui/core/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import fetchClient from "../components/utils/fetchClient";

import GoogleMapReact from "google-map-react";

import Footer from "./Footer";
import Navbar from "./Navbar";
import TabPanel from "./TabPanel";
import Gallery from "./Gallery";

import { withTranslation } from "react-multi-lang";

import withAuth from "../components/auth/withAuth";

const Map = () => <ApartmentIcon color="secondary" style={{ fontSize: 30 }} />;

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    button: {
        width: "100%",
        marginBottom: 15
    }
});

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`
    };
}

class Apartment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: "videos",
            isLoading: true,
            property: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Apartment");

        let property = await this.fetchProperty();

        this.setState({
            property: property,
            isLoading: false
        });
    }

    async fetchProperty() {
        return new Promise(function(resolve, reject) {
            fetchClient
                .get("guests/property")
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    handleChange(event, newValue) {
        this.setState({
            currentTab: newValue
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Apartment")} {this.state.isLoading ? "" : "(" + this.state.property.propertyLocation.propertyAddress + ")"}
                        </Typography>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.paper}>
                                    {this.state.isLoading ? (
                                        <CircularProgress className={classes.progress} />
                                    ) : (
                                        <div>
                                            <AppBar position="static">
                                                <Tabs variant="scrollable" value={this.state.currentTab} onChange={this.handleChange} aria-label={t("apartment.YourApartment")}>
                                                    <Tab value="videos" label={t("apartment.Videos")} {...a11yProps("videos")} />
                                                    <Tab value="description" label={t("apartment.Description")} wrapped {...a11yProps("description")} />
                                                    <Tab value="amenities" label={t("apartment.Amenities")} {...a11yProps("amenities")} />
                                                    <Tab value="specs" label={t("apartment.Specifications")} {...a11yProps("specs")} />
                                                    <Tab value="photos" label={t("apartment.Photos")} wrapped {...a11yProps("photos")} />
                                                    <Tab value="map" variant="fullWidth" label={t("apartment.Map")} {...a11yProps("map")} />
                                                </Tabs>
                                            </AppBar>
                                            <TabPanel value={this.state.currentTab} index="videos">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.property.videosContent
                                                    }}
                                                ></div>
                                            </TabPanel>
                                            <TabPanel value={this.state.currentTab} index="description">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.property.propertyDescription
                                                    }}
                                                ></div>
                                            </TabPanel>
                                            <TabPanel value={this.state.currentTab} index="amenities">
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        {this.state.isLoading ? (
                                                            <CircularProgress className={classes.progress} />
                                                        ) : (
                                                            <div>
                                                                <Typography variant="h6" paragraph>
                                                                    {t("apartment.Usual")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="utensils" /> {t("apartment.Kitchens")} : {t("apartment.Yes")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="wifi" /> {t("apartment.Internet")} : {t("apartment.Yes")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="snowflake" /> {t("apartment.AirConditioning")}{" "}
                                                                    {this.state.property.amenities.airConditioning ? t("apartment.Yes") : t("apartment.No")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="spray-can" /> {t("apartment.Toiletries")} : {t("apartment.Yes")}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        {this.state.isLoading ? (
                                                            <CircularProgress className={classes.progress} />
                                                        ) : (
                                                            <div>
                                                                <Typography variant="h6" paragraph>
                                                                    {t("apartment.Unique")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="coffee" /> {t("apartment.CoffeeMachine")} : {t("apartment.Yes")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="wind" /> {t("apartment.Hairdryer")} : {t("apartment.Yes")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="tshirt" /> {t("apartment.WashingMachine")}{" "} 
                                                                    {this.state.property.amenities.washingMachine ? t("apartment.Yes") : t("apartment.No")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="window-restore" /> {t("apartment.BedLinen")} : {t("apartment.Yes")}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={this.state.currentTab} index="specs">
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        {this.state.isLoading ? (
                                                            <CircularProgress className={classes.progress} />
                                                        ) : (
                                                            <div>
                                                                <Typography variant="h6" paragraph>
                                                                    {t("apartment.General")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="users" /> {t("apartment.People")} : {this.state.property.propertySpecs.maxCapacity}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="shower" /> {t("apartment.Bathrooms")} : {this.state.property.propertySpecs.numBathrooms}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="home" /> {t("apartment.Bedrooms")} : {this.state.property.propertySpecs.numBedrooms}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="cube" /> {t("apartment.Size")} : {this.state.property.propertySpecs.maxCapacity}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="sort-alpha-up-alt" /> {t("apartment.Lift")} :{" "}
                                                                    {this.state.property.amenities.lift ? t("apartment.Yes") : t("apartment.No")}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        {this.state.isLoading ? (
                                                            <CircularProgress className={classes.progress} />
                                                        ) : (
                                                            <div>
                                                                <Typography variant="h6" paragraph>
                                                                    {t("apartment.Bedrooms")}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="user" /> {t("apartment.SingleBeds")} : {this.state.property.propertySpecs.maxCapacity}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="couch" /> {t("apartment.SofaBeds")} : {this.state.property.propertySpecs.maxCapacity}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="user-friends" /> {t("apartment.DoubleBeds")} : {this.state.property.propertySpecs.maxCapacity}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="users" /> {t("apartment.People")} : {this.state.property.propertySpecs.maxCapacity}
                                                                </Typography>
                                                                <Typography variant="body1" paragraph>
                                                                    <FontAwesomeIcon icon="bed" /> {t("apartment.Beds")} :{" "}
                                                                    {parseInt(this.state.property.propertySpecs.numDoubleBeds) +
                                                                        parseInt(this.state.property.propertySpecs.numSingleBeds) +
                                                                        parseInt(this.state.property.propertySpecs.numSofaBeds)}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={this.state.currentTab} index="photos">
                                                <Grid item xs={12} md={12} lg={12}>
                                                    {this.state.isLoading ? (
                                                        <CircularProgress className={classes.progress} />
                                                    ) : (
                                                        <Gallery name={this.state.property.propertyName} images={this.state.property.propertyImages.full} />
                                                    )}
                                                </Grid>
                                            </TabPanel>

                                            <TabPanel value={this.state.currentTab} index="map">
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "450px"
                                                    }}
                                                >
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{
                                                            key: "AIzaSyA-cHXDmx3_ygpIKSRPmZ4g4ajL6Uy6eV8"
                                                        }}
                                                        center={{
                                                            lat: parseFloat(this.state.property.propertyLocation.propertyLat),
                                                            lng: parseFloat(this.state.property.propertyLocation.propertyLong)
                                                        }}
                                                        defaultZoom={16}
                                                    >
                                                        <Map
                                                            lat={this.state.property.propertyLocation.propertyLat}
                                                            lng={this.state.property.propertyLocation.propertyLong}
                                                            text={this.state.property.propertyName}
                                                        />
                                                    </GoogleMapReact>
                                                </div>

                                                <div
                                                    style={{
                                                        paddingTop: 10
                                                    }}
                                                >
                                                    <Typography variant="body1" paragraph>
                                                        {t("apartment.Address")} {this.state.property.propertyLocation.propertyAddress} ({t("apartment.Floor")}{" "}
                                                        {this.state.property.propertyLocation.propertyFloor}, {t("apartment.Door")} {this.state.property.propertyLocation.propertyDoor})
                                                    </Typography>

                                                    <Typography variant="h5" paragraph>
                                                        {t("apartment.PublicTransport")}
                                                    </Typography>
                                                    <Typography variant="h6" paragraph>
                                                        {t("apartment.AeroBusTitle")}
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        {t("apartment.AeroBus")}
                                                    </Typography>
                                                    <Button variant="contained" color="primary" target="_blank" href="http://www.aerobusbcn.com/en/buy-tickets?prt=20259" className={classes.button}>
                                                        {t("apartment.BuyNow")}
                                                    </Button>
                                                    <Typography variant="h6" paragraph>
                                                        {t("apartment.BusTitle")}
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        {t("apartment.Bus")}
                                                    </Typography>
                                                    <Typography variant="h6" paragraph>
                                                        {t("apartment.MetroTitle")}
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        {t("apartment.Metro")}
                                                    </Typography>
                                                    <Typography variant="h6" paragraph>
                                                        {t("apartment.TrainTitle")}
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        {t("apartment.Train")}
                                                    </Typography>
                                                    <Typography variant="h6" paragraph>
                                                        {t("apartment.ParkingTitle")}
                                                    </Typography>
                                                    <Typography variant="body1" paragraph>
                                                        {t("apartment.Parking")}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        target="_blank"
                                                        href={
                                                            "https://parclick.com/parking-barcelona?lt=" +
                                                            this.state.property.propertyLocation.propertyLat +
                                                            "&ln=" +
                                                            this.state.property.propertyLocation.propertyLong +
                                                            "&q=" +
                                                            encodeURIComponent(this.state.property.propertyLocation.propertyAddress) +
                                                            "&affiliate=f5f3"
                                                        }
                                                        className={classes.button}
                                                    >
                                                        {t("apartment.BookNow")}
                                                    </Button>
                                                </div>
                                            </TabPanel>
                                        </div>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Apartment)));
