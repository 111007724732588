import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withTranslation } from "react-multi-lang";
import Typography from "@material-ui/core/Typography";
import withAuth from "../components/auth/withAuth";
import Footer from "./Footer";
import Navbar from "./Navbar";

import fetchClient from "../components/utils/fetchClient";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

class Services extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            booking: []
        };
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Services");

        let booking = await this.fetchBooking();

        this.setState({
            booking: booking,
            isLoading: false
        });
    }

    async fetchBooking() {
        return new Promise(function(resolve, reject) {
            fetchClient
                .get("guests/booking")
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Services")}
                        </Typography>
                        <Paper className={classes.paper}>
                            {!this.state.isLoading ? (
                                <>
                                    <Typography variant="h5">{t("services.Transfer")}</Typography>
                                    <Typography variant="body1" paragraph component="div">
                                        <p>{t("services.TransferText1")}</p>

                                        <p>{t("services.TransferText2")}</p>
                                    </Typography>
                                    <Button variant="contained" color="primary" href={"https://bnb.welcomepickups.com/en/ext/ru/" + this.state.booking.propertyId + "?puid="+this.state.booking.refId} target="_blank">
                                        {t("services.BookTransfer")}
                                    </Button>

                                    <Typography style={{ marginTop: 15 }} variant="h5">
                                        {t("services.LuggageStorage")}
                                    </Typography>
                                    <Typography style={{ marginTop: 15 }} variant="h6">
                                        Stasher
                                    </Typography>
                                    <Typography variant="body1" paragraph component="div">
                                        <p>{t("services.LuggageStorageStasher")}</p>
                                    </Typography>
                                    <Button variant="contained" color="primary" href="https://stasher.com/?utm_source=partner&utm_medium=email&utm_campaign=stayunique" target="_blank">
                                        {t("services.BookLuggageStorageStasher")}
                                    </Button>

                                    <Typography variant="h6" style={{ marginTop: 15 }}>
                                        LuggageHero
                                    </Typography>
                                    <Typography variant="body1" paragraph component="div">
                                        <p>{t("services.LuggageStorageLugHero")}</p>
                                    </Typography>
                                    <Button variant="contained" color="primary" href="https://lughero.com/su" target="_blank">
                                        {t("services.BookLuggageStorageLugHero")}
                                    </Button>

                                    <Typography variant="h5" style={{ marginTop: 15 }}>
                                        {t("services.BabyEquipment")}
                                    </Typography>
                                    <Typography variant="body1" paragraph component="div">
                                        <p>{t("services.BabyEquipmentText")}</p>
                                    </Typography>
                                    <Button variant="contained" color="primary" href={"https://totsintow.com/?ref=Stay+U-nique"} target="_blank">
                                        {t("services.BookBabyEquipment")}
                                    </Button>
                                </>
                            ) : (
                                <CircularProgress />
                            )}
                        </Paper>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Services)));
