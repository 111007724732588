import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Bookmark from "@material-ui/icons/Bookmark";
import Home from "@material-ui/icons/Home";
import AddLocation from "@material-ui/icons/AddLocation";
import LocalParking from "@material-ui/icons/LocalParking";
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import NoMeetingRoom from "@material-ui/icons/NoMeetingRoom";
import People from "@material-ui/icons/People";
import VoiceOverOff from "@material-ui/icons/VoiceOverOff";
import Language from "@material-ui/icons/Language";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import EuroSymbol from "@material-ui/icons/EuroSymbol";

import fetchClient from "../components/utils/fetchClient";

import Footer from "./Footer";
import Navbar from "./Navbar";

import moment from "moment";

import { withTranslation } from "react-multi-lang";

import withAuth from "../components/auth/withAuth";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black",
        height: "100%"
    },
    icon: {
        marginRight: 5,
        display: "flex",
        alignItems: "center"
    }
});

class Booking extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            booking: []
        };
    }

    async componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.Booking");

        let booking = await this.fetchBooking();

        this.setState({
            booking: booking,
            isLoading: false
        });
    }

    async fetchBooking() {
        return new Promise(function(resolve, reject) {
            fetchClient
                .get("guests/booking")
                .then(function(response) {
                    resolve(response.data.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.Booking")}
                        </Typography>
                        <Paper className={classes.paper}>
                            {this.state.isLoading ? (
                                <CircularProgress className={classes.progress} />
                            ) : (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div>
                                            <Typography variant="h6" paragraph>
                                                {t("booking.BookingInformation")}
                                            </Typography>
                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <Bookmark /> {t("booking.BookingNumber")} : {this.state.booking.refId}
                                            </Typography>

                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <Home /> {t("booking.ApartmentName")} : {this.state.booking.propertyName}
                                            </Typography>

                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <MeetingRoom /> {t("booking.Arrival")} : {moment(this.state.booking.travelArrivalDate).format("DD/MM/YYYY HH:mm")}
                                            </Typography>

                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <NoMeetingRoom /> {t("booking.Departure")} : {moment(this.state.booking.travelDepartureDate).format("DD/MM/YYYY HH:mm")}
                                            </Typography>

                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <Language /> {t("booking.Channel")} : {this.state.booking.bookingChannelOriginName}
                                            </Typography>

                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <EuroSymbol /> {t("booking.Price")} : €{this.state.booking.totalPrice}
                                            </Typography>

                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <People /> {t("booking.Persons")} : {this.state.booking.totalPersons}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div>
                                            <Typography variant="h6" paragraph className={classes.icon}>
                                                {t("booking.ImportantInformation")}
                                            </Typography>

                                            {this.state.booking.bondRequired > 0 && (
                                                <Typography variant="body1" paragraph className={classes.icon}>
                                                    <MonetizationOn /> {t("booking.Deposit")} : €{this.state.booking.bondRequired}
                                                </Typography>
                                            )}

                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <VoiceOverOff /> {t("booking.QuietHours")} : 21:00 - 09:00
                                            </Typography>
                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <MeetingRoom /> {t("booking.CheckinTime")} : 15:00 - 21:00
                                            </Typography>
                                            {!this.state.booking.propertyAutoCheckin && (
                                                <Typography variant="body1" paragraph className={classes.icon}>
                                                    <Bookmark />
                                                    {t("booking.LateFee")} : 21.00 - 00:00 = €20 / 00:00-10:00 = €50
                                                </Typography>
                                            )}
                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <NoMeetingRoom />
                                                {t("booking.CheckoutTime")} : 11:00
                                            </Typography>
                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <LocalParking /> {t("booking.Parking")} : {t("booking.ParkingExplanation")}
                                            </Typography>
                                            <Typography variant="body1" paragraph className={classes.icon}>
                                                <AddLocation /> {t("booking.Address")} : {this.state.booking.address}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(Booking)));
