import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withTranslation } from "react-multi-lang";
import Typography from "@material-ui/core/Typography";
import withAuth from "../components/auth/withAuth";

import Footer from "./Footer";
import Navbar from "./Navbar";

const styles = theme => ({
    root: {
        display: "flex"
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto"
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

class About extends React.Component {
    componentDidMount() {
        document.title = "Stay U-nique | " + this.props.t("titles.About");
    }

    render() {
        const { t, classes } = this.props;

        return (
            <div className={classes.root}>
                <Navbar />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Typography variant="h4" paragraph>
                            {t("titles.About")}
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Paper className={classes.paper}>
                                    <Typography variant="h6" paragraph>
                                        {t("about.Slogan")}
                                    </Typography>
                                    <Typography variant="body1" paragraph>
                                        {t("about.Welcome")}
                                        <br />
                                        <br />
                                        <FontAwesomeIcon icon="envelope" /> info@stay-u-nique.com
                                        <br />
                                        <FontAwesomeIcon icon="phone-alt" /> +34 932 750 423
                                        <br />
                                        <FontAwesomeIcon icon={["fab", "whatsapp"]} /> +34 932 750 423 (10:00h-18:00h CET GMT+1)
                                        <br />
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer />
                </main>
            </div>
        );
    }
}

export default withTranslation(withAuth(withStyles(styles)(About)));
