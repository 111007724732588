import axios from "axios";
import { createHashHistory } from "history";

export const history = createHashHistory();

const fetchClient = () => {
    const defaultOptions = {
        baseURL: process.env.REACT_APP_API_URL,
        method: "get",
        headers: {
            "Content-Type": "application/json"
        }
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const token = localStorage.getItem("guestAppToken");

        var language = "en";
        var guestAppSettings = JSON.parse(localStorage.getItem("guestAppSettings"));
        if (guestAppSettings) {
            if (guestAppSettings.language) {
                language = guestAppSettings.language;
            }
        }

        config.headers = {
            "Content-Type": "application/json",
            Authorization: token ? `Bearer ${token}` : "",
            "Content-Language": language
        };

        return config;
    });

    instance.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    );

    const errorHandler = error => {
        if (error.response) {
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem("guestAppToken");
                history.replace("/login");
            }
        }

        return Promise.reject(error);
    };

    const successHandler = response => {
        return response;
    };

    return instance;
};

export default fetchClient();
