import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-multi-lang";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import TextField from "../fields/TextField";
import Select from "../fields/Select";
import Checkbox from "../fields/Checkbox";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    }
});

class PersonalInfoForm extends React.Component {
    render() {
        const { t, classes } = this.props;

        const required = value => (value ? undefined : t("arrival.Required"));

        const parse = value => {
            return value.replace(/[^\d+\s]/g, "");
        };

        const isPhoneValid = value => {
            if (!value) {
                return t("arrival.Required");
            }
            const phoneNumber = parsePhoneNumberFromString(value);
            if (phoneNumber) {
                return phoneNumber.isValid() ? undefined : t("arrival.InvalidPhone");
            } else {
                return t("arrival.InvalidPhone");
            }
        };

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("arrival.PersonalDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper className={classes.paper}>
                            <Field fullWidth name="customerFirstName" component={TextField} type="text" label={t("arrival.FirstName")} validate={required} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper className={classes.paper}>
                            <Field fullWidth name="customerLastName" component={TextField} type="text" label={t("arrival.LastName")} validate={required} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper className={classes.paper}>
                            <Field fullWidth name="customerRealEmail" component={TextField} type="text" label={t("arrival.Email")} validate={required} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper className={classes.paper}>
                            <Field fullWidth name="customerTel1" component={TextField} parse={parse} validate={isPhoneValid} label={t("arrival.Phone")} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Paper className={classes.paper}>
                            <Field native name="customerLanguageCode" component={Select} label={t("arrival.Language")} validate={required}>
                                <option value={"EN"}>{t("languages.English")}</option>
                                <option value={"ES"}>{t("languages.Spanish")}</option>
                                <option value={"FR"}>{t("languages.French")}</option>
                            </Field>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Field color="primary" label={t("arrival.MarketingConsent")} name="customerAllowContact" component={Checkbox} type="checkbox" />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(PersonalInfoForm));
