import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SignatureCanvas from "react-signature-canvas";
import { withTranslation } from "react-multi-lang";

const styles = theme => ({
    root: {
        marginTop: 20,
        marginBottom: 20
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        fontcolor: "black"
    },
    signature: {
        display: "none"
    }
});

function Contract(props) {
    return <div dangerouslySetInnerHTML={{ __html: props.input.value }}></div>;
}

function Link(props) {
    return (
        <Button color="primary" target="_blank" variant="contained" href={props.input.value}>
            {props.t("arrival.ViewContract")}
        </Button>
    );
}

class ContractForm extends React.Component {
    constructor(props) {
        super(props);

        this.sigCanvas = React.createRef();
    }

    clearPad = () => {
        this.sigCanvas.current.clear();
        this.props.updateValue("signature", null);
    };

    toDataUrl = () => {
        let image = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        this.props.updateValue("signature", image);
    };

    render() {
        const { t, classes, values } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" paragraph>
                                {t("arrival.ContractDesc")}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="body1" component="div">
                                <Field name="contract" component={Contract} />
                                <Field name="signature" component="input" className={classes.signature} validate={value => (value || values.contractDone ? undefined : t("arrival.Required"))} />
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            {values.contractDone === 0 ? (
                                <>
                                    <SignatureCanvas penColor="black" ref={this.sigCanvas} canvasProps={{ width: 800, height: 200 }} onEnd={this.toDataUrl} />
                                    <Button color="primary" onClick={this.clearPad} variant="contained">
                                        {t("arrival.ClearSig")}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1" paragraph>
                                        {t("arrival.ContractSigned")}
                                    </Typography>
                                    <Field name="contractLink" t={t} component={Link} />
                                </>
                            )}{" "}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withTranslation(withStyles(styles)(ContractForm));
