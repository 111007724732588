import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./App.css";
import * as serviceWorker from "./serviceWorker";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Login from "./components/Login";
import About from "./components/About";
import Apartment from "./components/Apartment";
import Booking from "./components/Booking";
import Arrival from "./components/arrival/Arrival";
import GettingIn from "./components/GettingIn";
import Activities from "./components/Activities";
import Guide from "./components/guide/Guide";
import Rate from "./components/Rate";
import Review from "./components/Review";
import Payment from "./components/Payment";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentError from "./components/PaymentError";
import Issue from "./components/Issue";
import Services from "./components/Services";
import WiFi from "./components/WiFi";
import Contracts from "./components/Contracts";
import Chat from "./components/Chat";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import withTracker from "./withTracker";

library.add(fas, fab);

const theme = createMuiTheme({
    palette: {
        primary: { main: "#4e5e9e", contrastText: "#ffffff" },
        secondary: { main: "#53c3e6", contrastText: "#000000" }
    },
    typography: {
        useNextVariants: true
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        }
    }
});

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/arrival/:key?" component={withTracker(Arrival)} />
                        <Route exact path="/passport/:key?" component={withTracker(Arrival)} />
                        <Route exact path="/payments-step/:key?" component={withTracker(Arrival)} />
                        <Route exact path="/login" component={withTracker(Login)} />
                        <Route exact path="/about" component={withTracker(About)} />
                        <Route exact path="/your-booking" component={withTracker(Booking)} />
                        <Route exact path="/your-guide" component={withTracker(Guide)} />
                        <Route exact path="/your-apartment" component={withTracker(Apartment)} />
                        <Route exact path="/getting-in/:key?" component={withTracker(GettingIn)} />
                        <Route exact path="/activities" component={withTracker(Activities)} />
                        <Route exact path="/rate/:key?" component={withTracker(Rate)} />
                        <Route exact path="/review/:key?" component={withTracker(Review)} />
                        <Route exact path="/payment/:id" component={withTracker(Payment)} />
                        <Route exact path="/payment-success" component={withTracker(PaymentSuccess)} />
                        <Route exact path="/payment-error" component={withTracker(PaymentError)} />
                        <Route exact path="/issue/:key?" component={withTracker(Issue)} />
                        <Route exact path="/services" component={withTracker(Services)} />
                        <Route exact path="/wifi" component={withTracker(WiFi)} />
                        <Route exact path="/contracts/:key?" component={withTracker(Contracts)} />
                        <Route exact path="/chat" component={withTracker(Chat)} />
                        <Redirect to="/arrival" />
                    </Switch>
                </HashRouter>
            </MuiThemeProvider>
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
